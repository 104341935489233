import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
import authReducer, { AuthState } from "./auth/authSlice";
import treeReducer, { initialState, TreeState } from "./tree/treeSlice";
import tourReducer from "./tour/tourSlice";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import createMigrate from "redux-persist/es/createMigrate";
import { PersistConfig } from "redux-persist/es/types";
import { TourState } from "./tour/tourSlice";

const rootPersistConfig = {
  key: "root",
  storage,
  blacklist: [], // do not persist these keys
  whitelist: undefined, // only persist these keys
};

const authPersistConfig: PersistConfig<AuthState> = {
  key: "auth",
  storage,
  blacklist: [], // do not persist these keys
  whitelist: undefined, // only persist these keys,
};

const treePersistConfig: PersistConfig<TreeState> = {
  key: "tree",
  storage,
};

const tourPersistConfig: PersistConfig<TourState> = {
  key: "tour",
  storage,
};

const reducers = combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
  tour: persistReducer(tourPersistConfig, tourReducer),
  tree: persistReducer(treePersistConfig, treeReducer),
});

const persistedReducer = persistReducer(rootPersistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, REGISTER],
    },
  }),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
