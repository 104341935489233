import {
  CloseCircleOutlined,
  FolderOutlined,
  PlusCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Button, DatePicker, Input, Select, Tooltip } from "antd";
import { useState } from "react";
import { COLORS } from "../../Constants";
import useClickOutside from "../../util/useClickOutside";
import { selectTags } from "../../store/auth/authSlice";
import { useAppSelector } from "../../store/hooks";
import { FileDto, FileFilterDto } from "./Types";
import { useHistory } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import { FILE_DATE_FORMAT } from "../../util/DateUtil";
import { color, isTransparent } from "html2canvas/dist/types/css/types/color";
import { divide, unset } from "lodash";
import React from "react";
import AlbumModal from "./AlbumModal";
import { createAlbumAPI } from "../../service/albumAPI";
import Bread from "./Bread";

const { Option } = Select;

const { RangePicker } = DatePicker;
export interface FilesHeaderProps {
  onClose: (update: boolean, copiedFile?: FileDto | undefined) => void;
  onFilter: (filter: FileFilterDto, fileTypes: string[] | undefined) => void;
  safe?: boolean;
  currentAlbum?: string;
  albumTitle?: string;
  currentFile?: FileDto;
  readOnly?: boolean;
  onLinkedAlbum: () => void;
  onOpenFileForm: () => void;
  onCreateAlbum: () => void;
}

export const FilesHeader: React.FunctionComponent<FilesHeaderProps> = (
  props
) => {
  const [showSearcDetails, setshowSearcDetails] = useState(false);
  const history = useHistory();

  const tags = useAppSelector(selectTags);
  const [showGroupDetails, setShowGroupDetails] = useState(false);
  const [filter, setFilter] = useState<FileFilterDto>();
  const [fileTypes, setFileTypes] = useState<string[] | undefined>();

  const allFileTypes: { name: string; id: string }[] = [
    { id: "image", name: "Foto" },
    { id: "video", name: "Video" },
    { id: "audio", name: "Audio" },
    { id: "text", name: "Testo" },
    { id: "pdf", name: "PDF" },
    { id: "spreadsheet", name: "Foglio di calcolo" },
    { id: "presentation", name: "Presentazione" },
  ];
  return (
    <div className="header">
      <div className="titleheader">
        {props.currentAlbum ? (
          <>
            <div className="back-button" onClick={() => history.goBack()}>
              <CloseCircleOutlined style={{ marginRight: 10 }} />
              Chiudi
            </div>
            <div className="label hidelongtext">{props.albumTitle}</div>
          </>
        ) : null}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          className="searchbutton"
          onClick={() => {
            setshowSearcDetails(true);
            console.log(showSearcDetails);
          }}
        >
          <SearchOutlined
            style={{ fontSize: 18, margin: 5, color: COLORS.purple }}
          ></SearchOutlined>
          Cerca un File
        </div>
        {showSearcDetails && (
          <div className="edit-search-container">
            <div className="closediv">
              <span
                className="close"
                onClick={() => {
                  setshowSearcDetails(false);
                }}
              >
                {" "}
                &times;
              </span>
            </div>

            <Input
              placeholder="Nome.."
              allowClear
              style={{ width: 260 }}
              value={filter?.name}
              onChange={(e) => {
                setFilter({
                  ...filter,
                  name: e.target.value === "" ? undefined : e.target.value,
                });
              }}
            />

            <Select
              mode="multiple"
              style={{ width: 260, marginTop: 20 }}
              maxTagCount="responsive"
              placeholder="Tipo di file"
              allowClear
              onChange={(v) => {
                let types = v as string[];
                setFileTypes(types.length > 0 ? types : undefined);
              }}
            >
              {allFileTypes.map((tag) => (
                <Option key={tag.id} value={tag.id}>
                  {tag.name}
                </Option>
              ))}
            </Select>

            <Select
              mode="multiple"
              style={{ width: 260, marginTop: 20 }}
              maxTagCount="responsive"
              placeholder="TAG.."
              allowClear
              onChange={(v) => {
                let tags = v as string[];
                setFilter({
                  ...filter,
                  tag: tags.length > 0 ? tags : undefined,
                });
              }}
            >
              {tags?.map((tag) => (
                <Option key={tag.tagId} value={tag.name}>
                  {tag.name}
                </Option>
              ))}
            </Select>
            <RangePicker
              style={{ marginTop: 20 }}
              allowClear
              size="small"
              format={FILE_DATE_FORMAT}
              onChange={(m, str) => {
                setFilter({
                  ...filter,
                  fromDate: str[0] === "" ? undefined : str[0],
                  toDate: str[1] === "" ? undefined : str[1],
                });
              }}
            />
            <Button
              style={{ width: 260, marginTop: 150 }}
              onClick={() => {
                if (filter || fileTypes)
                  props.onFilter(filter ?? {}, fileTypes);
              }}
            >
              Cerca
            </Button>
          </div>
        )}
        <div className="add-document-btn">
          <Button
            shape="round"
            onClick={() => {
              props.onCreateAlbum();
            }}
          >
            <div>
              Nuovo Album
              <FolderOutlined
                style={{ marginLeft: 5, fontSize: 15, color: COLORS.purple }}
              >
                {" "}
              </FolderOutlined>
            </div>
          </Button>
          <Button
            shape="round"
            style={{ color: COLORS.purple }}
            onClick={() => {
              props.onOpenFileForm();
            }}
          >
            <div style={{}}>
              Aggiungi Documento{" "}
              <PlusCircleOutlined
                style={{
                  marginLeft: 5,
                  fontSize: 15,
                  color: COLORS.purple,
                }}
              />
            </div>
          </Button>
        </div>
      </div>
      <div style={{ borderBottom: "1px solid" }} />
      {!props.currentAlbum && (
        <div className="search-bar">
          <Bread />
        </div>
      )}
    </div>
  );
};
