import React from "react";

interface PageNotFoundProps {}

const PageNotFound: React.FunctionComponent<PageNotFoundProps> = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100vw",
        height: "100vh",
        gap: 30,
      }}
    >
      <img
        style={{ width: 200 }}
        src="/assets/images/logo_fw_black.png"
        alt="logo"
      />
      <h3>{"404 - Pagina non trovata"}</h3>
      <a href="/home">{"Torna alla home"}</a>
    </div>
  );
};

export default PageNotFound;
