import { Tooltip } from "antd";
import { FunctionComponent } from "react";

interface FolderContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  label: string;
  icon?: React.ReactNode;
  tooltip?: string;
}

const FolderContainer: FunctionComponent<FolderContainerProps> = ({
  label,
  icon,
  tooltip,
  ...rest
}) => {
  return (
    <div style={{ height: 150 }} {...rest}>
      <div style={{ position: "relative" }}>
        <img
          src="/assets/images/purple_folder.png"
          width={"100%"}
          height={100}
        />
        <div style={{ position: "absolute" }}>{icon}</div>
      </div>

      {/* <Tooltip title={tooltip}></Tooltip> */}
      <div style={{ paddingLeft: 25 }}>{label}</div>
    </div>
  );
};

export default FolderContainer;
