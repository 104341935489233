import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import axios from "axios";
import { RootState } from "../";
import { FileDto, Tag } from "../../components/files/Types";
import { PersonDto } from "../../components/tree/TreeTypes";
import { FirebaseConfig } from "../../FirebaseConfig";
import { toast } from "react-toastify";
import { retrieveUserAlbumsAPI } from "../../service/albumAPI";
import {
  createPerson,
  login,
  loginDto,
  registerPersonDto,
  register,
  registerDto,
  registerPerson,
  refreshTokenAPI,
  registerWithcode,
} from "../../service/authAPI";
import { retrieveTagAsAlbum, retrieveUserTags } from "../../service/fileAPI";
import { ConnectionsDTO, retrieveUserGroupsAPI } from "../../service/groupAPI";
import {
  acceptNotificationAPI,
  readNotificationAPI,
  rejectNotificationAPI,
  retrieveNotificationsAPI,
} from "../../service/notificationAPI";
import { getActiveSubscription } from "../../service/paymentAPI";
import { getUsedStorage, getUserProfile } from "../../service/userAPI";
import { AlbumInterface } from "../../types/AlbumTypes";
import { NotificationInterface } from "../../types/NotificationTypes";
import { Subscription } from "../../types/PaymentTypes";
import { isActive } from "../../util/PaymentUtil";
import { resetTour, retrieveTourStatus } from "../tour/tourSlice";
import { resetTree } from "../tree/treeSlice";

export interface UserStorage {
  usedByOthers: number;
  usedStorage: number;
  storage: number;
}
export interface AuthState {
  token?: string;
  refreshToken?: string;
  isRefreshingToken: boolean;
  profile?: PersonDto;
  loadNewPost: boolean;
  notifications: {
    data: NotificationInterface[];
    pendingNotifications: number;
    token?: string | null;
  };
  error: "user" | "password" | "exuser" | "generic" | null;
  status: "idle" | "loading" | "failed";
  subscription?: Subscription;
  userStorage?: UserStorage;
  connections?: ConnectionsDTO;
  tags?: Tag[];
  tagAlbums?: AlbumInterface[];
  albums?: AlbumInterface[];
}

const initialState: AuthState = {
  token: undefined,
  refreshToken: undefined,
  isRefreshingToken: false,
  profile: undefined,
  loadNewPost: false,
  notifications: {
    data: [],
    pendingNotifications: 0,
  },
  error: null,
  status: "idle",
  userStorage: {
    usedStorage: 0,
    usedByOthers: 0,
    storage: 0,
  },
  subscription: undefined,
  connections: undefined,
  tagAlbums: [],
  tags: [],
  albums: [],
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const loginAsync = createAsyncThunk(
  "auth/login",
  async (dto: loginDto, { rejectWithValue, dispatch }) => {
    const response = await login(dto).then(async (resp) => {
      console.log(resp);
      if (resp?.data && resp.data.message && resp.data.error) {
        return resp.data.message;
      }
      setAxiosToken(resp?.data.access_token);
      let profile = await getUserProfile(dto.username);
      let pushToken = null;
      try {
        pushToken = await FirebaseConfig.initMessaging(profile?.personId!);
      } catch (e) {
        console.error(e);
      }

      console.log(profile);
      return { ...resp, profile, pushToken };
    });
    console.log(response);
    // The value we return becomes the `fulfilled` action payload
    if (response?.data) {
      let sub;
      if (response.profile) {
        dispatch(retrieveNotifications(response.profile.personId));
        dispatch(retrieveStorage(response.profile.personId));
        dispatch(retrieveTourStatus(response.profile.personId));
        dispatch(retrieveUserSubscription(response.profile.personId));
        dispatch(updateAlbums(response.profile.personId));
        dispatch(updateTags({ userId: response.profile.personId }));

        sub = await getActiveSubscription(response.profile?.personId);
      } else return rejectWithValue("profile");
      return {
        token: response?.data.access_token,
        refreshToken: response?.data.refresh_token,
        username: dto.username,
        profile: response.profile,
        sub,
        pushToken: response.pushToken,
      };
    }
    return rejectWithValue(response);
  }
);

export const refreshTokenAsync = createAsyncThunk<{
  token?: string;
  refreshToken?: string;
}>(
  "auth/refreshToken",
  async (_payload, { getState, rejectWithValue, dispatch }) => {
    const state = getState() as RootState;
    if (state.auth.isRefreshingToken) {
      console.log("already refreshing..");
      return rejectWithValue(undefined);
    }
    dispatch(setRefreshingToken(true));
    const refreshToken = state.auth.refreshToken;
    const response = await refreshTokenAPI(refreshToken!).then((resp) => {
      if (resp?.data && resp.data.message && resp.data.error) {
        return resp.data.message;
      }

      setAxiosToken(resp?.data.access_token);
      return resp;
    });

    dispatch(setRefreshingToken(false));

    if (response?.data) {
      return {
        token: response?.data.access_token,
        refreshToken: response?.data.refresh_token,
      };
    }

    dispatch(authLogout());
    return rejectWithValue(response);
  }
);

export const newPerson = createAsyncThunk(
  "auth/newPerson",
  async (
    {
      newDto,
    }: {
      newDto: registerPersonDto;
    },
    { rejectWithValue, dispatch }
  ) => {
    await createPerson(newDto).catch((error) => {
      return rejectWithValue(error);
    });

    let profile;

    if (newDto) {
      profile = await getUserProfile(newDto.username);
      if (profile) {
        dispatch(retrieveNotifications(profile.personId));
        dispatch(retrieveStorage(profile.personId));
        dispatch(retrieveTourStatus(profile.personId));
        dispatch(retrieveUserSubscription(profile.personId));
      } else return rejectWithValue("profile");
    }

    return {
      profile,
    };
  }
);

export const linkPerson = createAsyncThunk(
  "auth/linkPerson",
  async (person: PersonDto, { rejectWithValue, dispatch }) => {
    let resp = await registerPerson(person);
    if (resp) {
      if (person) {
        dispatch(retrieveNotifications(person.personId));
        dispatch(retrieveStorage(person.personId));
        dispatch(retrieveTourStatus(person.personId));
        dispatch(retrieveUserSubscription(person.personId));
        return { profile: person };
      }
    } else {
      toast.error(
        "Non è stato possibile associare l'utente alla persona esistente",
        {
          position: "bottom-right",
          hideProgressBar: true,
        }
      );
    }
  }
);

export const registerAsync = createAsyncThunk(
  "auth/register",
  async (
    {
      registerDto,
      captchaToken,
    }: {
      registerDto: registerDto;
      captchaToken: string;
    },
    { rejectWithValue, dispatch }
  ) => {
    setAxiosToken();
    const resp = await register(registerDto, captchaToken).then(async (res) => {
      console.log(res);
      if (res?.data && res.data.message && res.data.error) {
        return res.data.message;
      }
      return await login(registerDto).then(async (loginResp) => {
        setAxiosToken(loginResp?.data.access_token);

        return loginResp;
      });
    });

    if (typeof resp === "string") {
      console.log(resp);
      return rejectWithValue(resp);
    }

    FirebaseConfig.log("sign_up");

    return {
      token: resp?.data.access_token,
      refreshToken: resp?.data.refresh_token,
      username: registerDto.username,
    };
  }
);

export const registerWithCodeAsync = createAsyncThunk(
  "auth/registerWithCode",
  async (
    {
      dto,
      invite,
      captchaToken,
    }: {
      dto: PersonDto;
      invite: string;
      captchaToken: string;
    },
    { rejectWithValue, dispatch }
  ) => {
    setAxiosToken();
    const resp = await registerWithcode(dto, invite, captchaToken).then(
      async (res) => {
        console.log(res);
        if (res?.data && res.data.message && res.data.error) {
          return res.data.message;
        }
        return await login({
          username: dto.username!,
          password: dto.password!,
        }).then(async (loginResp) => {
          setAxiosToken(loginResp?.data.access_token);

          return loginResp;
        });
      }
    );

    let profile = await getUserProfile(dto.username!);
    if (profile) {
      dispatch(retrieveNotifications(profile.personId));
      dispatch(retrieveStorage(profile.personId));
      dispatch(retrieveTourStatus(profile.personId));
      dispatch(retrieveUserSubscription(profile.personId));
    } else return rejectWithValue("profile");

    if (typeof resp === "string") {
      console.log(resp);
      return rejectWithValue(resp);
    }

    FirebaseConfig.log("sign_up");

    return {
      token: resp?.data.access_token,
      refreshToken: resp?.data.refresh_token,
      profile,
      username: dto.username,
    };
  }
);

export const updateTagAlbums = createAsyncThunk(
  "auth/updateTagAlbums",
  async (
    { userId, safe, tags }: { userId?: string; safe?: boolean; tags: Tag[] },
    thunk
  ): Promise<AlbumInterface[]> => {
    const mainState = thunk.getState() as RootState;
    let tagAlbums = await Promise.all(
      tags.map(
        async (tag) =>
          await retrieveTagAsAlbum(
            userId ?? mainState.auth.profile?.personId!,
            tag.name,
            safe,
            0,
            2
          )
      )
    );
    let albums: AlbumInterface[] = [];
    tagAlbums.forEach((t) => {
      if (t !== undefined) albums.push(t);
    });
    if (albums) return albums;
    else thunk.rejectWithValue("error loading tags");
    return [];
  }
);

export const updateTags = createAsyncThunk(
  "auth/updateTags",
  async (args: { userId?: string; safe?: boolean } | undefined, thunk) => {
    const mainState = thunk.getState() as RootState;
    let tags = await retrieveUserTags(
      args?.userId ?? mainState.auth.profile?.personId!
    );
    if (tags) {
      if (
        tags
          .map((t) => t.tagId)
          .sort()
          .join(",") !==
        mainState.auth.tags
          ?.map((t) => t.tagId)
          .sort()
          .join(",")
      )
        thunk.dispatch(
          updateTagAlbums({ userId: args?.userId, tags, safe: args?.safe })
        );

      return tags;
    } else {
      thunk.rejectWithValue("error loading tags");
    }
  }
);

export const updateAlbums = createAsyncThunk(
  "auth/updateAlbums",
  async (userId: string | undefined, thunk) => {
    const mainState = thunk.getState() as RootState;
    let albums = await retrieveUserAlbumsAPI(
      userId ?? mainState.auth.profile?.personId!
    );
    if (albums) {
      return albums;
    } else {
      thunk.rejectWithValue("error loading albums");
    }
  }
);

export const updateConnections = createAsyncThunk(
  "auth/updateConnections",
  async (p, thunk) => {
    const mainState = thunk.getState() as RootState;

    let dto = await retrieveUserGroupsAPI(mainState.auth.profile?.personId!);

    // if image was never added, was cleared, or needs to be updated
    if (dto) {
      return dto;
    } else {
      thunk.rejectWithValue("error loading connections");
    }
  }
);

export const updateUserPic = createAsyncThunk(
  "auth/updatePic",
  async (dto: FileDto, thunk) => {
    const mainState = thunk.getState() as RootState;
    const userPic = mainState.auth?.profile?.userPic;

    // if image was never added, was cleared, or needs to be updated

    if (
      !userPic ||
      !userPic.path ||
      //(userPic.path && !imageExists(userPic.path)) || //Condition removed as it always returns false
      userPic.file?.fileId !== dto.fileId
    ) {
      const url = dto.thumbnail_uri;
      return {
        userPicPath: url,
        userPic: dto,
      };
    } else {
      thunk.rejectWithValue("no need to download image");
    }
  }
);

export const retrieveProfile = createAsyncThunk(
  "auth/retrieveProfile",
  async (userId: string) => {
    let profile = await getUserProfile(userId);
    return { profile };
  }
);

export const retrieveNotifications = createAsyncThunk(
  "auth/retrieveNotification",
  async (userId: string) => {
    const results = await retrieveNotificationsAPI(userId.toString(), true);
    return results;
  }
);

export const retrieveStorage = createAsyncThunk(
  "auth/retrieveStorage",
  async (userId: string | undefined, { getState, dispatch }) => {
    const state = getState() as RootState;
    const results = await getUsedStorage(
      userId ?? state.auth.profile?.personId ?? ""
    );
    return results;
  }
);

export const retrieveUserSubscription = createAsyncThunk(
  "auth/retrieveUserSubscription",
  async (userId: string | undefined, { getState, dispatch }) => {
    const state = getState() as RootState;

    const sub = await getActiveSubscription(
      userId ?? state.auth.profile?.personId ?? ""
    );
    return sub;
  }
);

interface RespondRequestPayload {
  index: number;
  accepted: boolean;
}

export const respondRequest = createAsyncThunk(
  "auth/respondRequest",
  async (payload: RespondRequestPayload, { getState, dispatch }) => {
    const { index, accepted } = payload;
    const state = getState() as RootState;
    const userID = state.auth.profile?.personId;
    const notification = state.auth.notifications.data[index];
    if (userID && notification) {
      if (accepted)
        await acceptNotificationAPI(userID, notification.notificationId);
      else await rejectNotificationAPI(userID, notification.notificationId);

      dispatch(retrieveNotifications(userID));
    }
  }
);

export const readNotification = createAsyncThunk(
  "auth/readNotification",
  async (index: number, { getState }) => {
    const state = getState() as RootState;
    const userID = state.auth.profile?.personId;
    const notification = state.auth.notifications.data[index];
    if (userID && notification) {
      await readNotificationAPI(userID, [notification.notificationId]);
    }
  }
);

export const authLogout = createAsyncThunk(
  "auth/logout",
  (_payload, { dispatch }) => {
    dispatch(resetTree());
    dispatch(resetAuth());
    dispatch(resetTour());
    setAxiosToken(undefined);
    localStorage.clear();
    sessionStorage.clear();
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetAuth: (state) => {
      state = initialState;
    },

    setLoadNewPost: (state, action: PayloadAction<boolean>) => {
      state.loadNewPost = action.payload;
    },

    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    setRefreshingToken: (state, action: PayloadAction<boolean>) => {
      state.isRefreshingToken = action.payload;
    },
  },

  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder

      .addCase(authLogout.fulfilled, (state) => {
        state.token = undefined;
        state.refreshToken = undefined;
        state.profile = undefined;
        state.userStorage = undefined;
        state.notifications = { data: [], pendingNotifications: 0 };
      })

      // Login
      .addCase(loginAsync.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(loginAsync.fulfilled, (state, action: { payload: any }) => {
        console.log(action.payload);

        state.status = "idle";
        state.token = action.payload?.token;
        state.refreshToken = action.payload?.refreshToken;
        state.profile = action.payload?.profile;
        state.error = null;

        state.notifications.token = action.payload.pushToken;

        state.subscription = action.payload.sub;

        try {
          FirebaseConfig.setUserId(action.payload.profile.personId);
          FirebaseConfig.log("login");
        } catch (e) {
          console.log(e);
        }

        setAxiosToken(action.payload.token);
      })
      .addCase(loginAsync.rejected, (state, action) => {
        state.status = "failed";
        state.token = undefined;
        state.refreshToken = undefined;
        state.profile = undefined;
        if (action.payload && (action.payload as string).includes("user"))
          state.error = "user";
        else if (action.payload && (action.payload as string).includes("401"))
          state.error = "password";
        else state.error = "generic";
      })
      // Register
      .addCase(registerAsync.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(registerAsync.fulfilled, (state, action: { payload: any }) => {
        console.log(action.payload);
        state.status = "idle";
        state.token = action.payload?.token;
        state.refreshToken = action.payload?.refreshToken;
        state.error = null;
      })
      .addCase(registerAsync.rejected, (state, action) => {
        state.status = "failed";
        state.token = undefined;
        state.refreshToken = undefined;

        if (
          action.payload &&
          ((action.payload as string).includes("user") ||
            (action.payload as string).includes("email"))
        ) {
          state.error = "exuser";
        } else {
          state.error = "generic";
        }
      })
      .addCase(registerWithCodeAsync.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(
        registerWithCodeAsync.fulfilled,
        (state, action: { payload: any }) => {
          console.log(action.payload);
          state.status = "idle";
          state.profile = action.payload?.profile;
          state.token = action.payload?.token;
          state.refreshToken = action.payload?.refreshToken;
          state.error = null;
        }
      )
      .addCase(registerWithCodeAsync.rejected, (state, action) => {
        state.status = "failed";
        state.token = undefined;
        state.refreshToken = undefined;

        if (
          action.payload &&
          ((action.payload as string).includes("user") ||
            (action.payload as string).includes("email"))
        ) {
          state.error = "exuser";
        } else {
          state.error = "generic";
        }
      })
      .addCase(retrieveUserSubscription.fulfilled, (state, action) => {
        state.subscription = action.payload;
      })
      // new person
      .addCase(newPerson.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(newPerson.fulfilled, (state, action: { payload: any }) => {
        console.log(action.payload);
        state.status = "idle";
        state.profile = action.payload?.profile;
        state.error = null;
      })
      .addCase(newPerson.rejected, (state, action) => {
        state.status = "failed";
        state.profile = undefined;
      })

      //linkPerson
      .addCase(linkPerson.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(linkPerson.fulfilled, (state, action) => {
        console.log(action.payload);
        state.status = "idle";
        state.profile = action.payload?.profile;
        state.error = null;
      })
      // UpdateUserPic
      //.addCase(updateUserPic.pending, (state, action) => {})
      .addCase(updateUserPic.fulfilled, (state, action) => {
        if (state.profile?.userPic && action.payload?.userPic) {
          state.profile.userPic.file = action.payload?.userPic;
          state.profile.userPic.path = action.payload?.userPicPath;
        }
      })
      .addCase(updateUserPic.rejected, (state, action) => {
        if (state.profile?.userPic) {
          state.profile.userPic.path = undefined;
        }
      })
      .addCase(updateConnections.fulfilled, (state, action) => {
        if (action.payload) {
          state.connections = action.payload;
        }
      })
      .addCase(retrieveProfile.fulfilled, (state, action) => {
        state.profile = action.payload.profile;
      })
      // Retrieve notifications
      .addCase(retrieveNotifications.fulfilled, (state, action) => {
        state.notifications = action.payload;
      })

      // Retrieve storage
      .addCase(retrieveStorage.fulfilled, (state, action) => {
        if (action.payload) state.userStorage = action.payload;
      })

      // RespondFriendRequest
      .addCase(respondRequest.pending, (state, action) => {
        state.notifications.data[action.meta.arg.index].loading = true;
      })
      .addCase(respondRequest.fulfilled, (state, action) => {
        const index = action.meta.arg.index;
        state.notifications.data.splice(index, 1);
        state.notifications.pendingNotifications--;
      })
      .addCase(respondRequest.rejected, (state, action) => {
        state.notifications.data[action.meta.arg.index].loading = false;
      })
      // Read Notification
      .addCase(readNotification.pending, (state, action) => {
        state.notifications.data[action.meta.arg].loading = true;
      })
      .addCase(readNotification.fulfilled, (state, action) => {
        const index = action.meta.arg;
        state.notifications.data.splice(index, 1);
        state.notifications.pendingNotifications--;
      })
      .addCase(readNotification.rejected, (state, action) => {
        state.notifications.data[action.meta.arg].loading = false;
      })

      .addCase(updateAlbums.fulfilled, (state, action) => {
        if (action.payload) state.albums = action.payload;
      })
      .addCase(updateTags.fulfilled, (state, action) => {
        if (action.payload) state.tags = action.payload;
      })
      .addCase(updateTagAlbums.fulfilled, (state, action) => {
        if (action.payload) state.tagAlbums = action.payload;
      })
      .addCase(
        refreshTokenAsync.fulfilled,
        (state, action: { payload: any }) => {
          console.log(action.payload);
          state.token = action.payload.token;
          state.refreshToken = action.payload.refreshToken;

          setAxiosToken(action.payload.token);
        }
      );
    /*.addCase(refreshTokenAsync.rejected, (state, action) => {
        authLogout();
      });*/
  },
});

export const { resetAuth, setLoadNewPost, setToken, setRefreshingToken } =
  authSlice.actions;

export const selectConnections = (state: RootState) => state.auth.connections;
export const selectTags = (state: RootState) => state.auth.tags;
export const selectTagAlbums = (state: RootState) => state.auth.tagAlbums;

export const selectAlbums = (state: RootState) => state.auth.albums;

export const selectGroupForFriend = (friendId: string) => (state: RootState) =>
  state.auth.connections?.friends.find(
    (f) =>
      friendId !== state.auth.profile?.personId &&
      (f.source.personId === friendId || f.target.personId === friendId)
  );

export const selectLoginError = (state: RootState) => state.auth.error;
export const selectAuthenticated = (state: RootState) => !!state.auth.token;
export const selectHasActiveSubscription = (state: RootState) => {
  return state.auth.subscription && isActive(state.auth.subscription);
};
export const selectLoadNewPost = (state: RootState) => state.auth?.loadNewPost;

export const selectUsername = (state: RootState) =>
  state.auth?.profile?.username;
export const selectToken = (state: RootState) => state.auth.token;
export const selectProfile = (state: RootState) => state.auth.profile;
export const selectUserPic = (state: RootState) =>
  state.auth.profile?.userPic?.file;
export const selectUserPicPath = (state: RootState) =>
  state.auth.profile?.userPic?.path;
export const selectNotifications = (state: RootState) =>
  state.auth.notifications;

export const selectUserStorage = (state: RootState) => state.auth.userStorage;

export default authSlice.reducer;

//Helpers

export const setAxiosToken = (token?: string) => {
  axios.defaults.headers["Authorization"] = !!token ? "Bearer " + token : null;
};
