import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import React, { useState } from "react";

var missingKeys: {
  [key: string]: string;
} = {};

var timer: NodeJS.Timeout | null;

export const Localize: React.FunctionComponent = (props) => {
  const [ready, setReady] = useState<boolean>(false);
  console.log("preparing i18n");
  i18n
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    .use(HttpApi)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    .init({
      react: {
        useSuspense: false,
      },
      backend: {
        addPath: "locales/add/it/translation",
      },
      fallbackLng: "it",
      debug: false,
      saveMissing: true, // new strings will be posted to backend
      missingKeyHandler: (lng, ns, key, fallbackValue) => {
        if (missingKeys[key] !== undefined) return;
        missingKeys[key] = key;
        if (timer !== null) clearTimeout(timer);
        timer = setTimeout(function () {
          console.warn(
            "MISSING TRANSLATIONS (see below, add them to public/locales/" +
              lng +
              ")"
          );
          console.log(JSON.stringify(missingKeys));
        }, 3000);
      },
      keySeparator: false, // we do not use keys in form messages.welcome
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
    })
    .then(() => setReady(true));

  if (ready) return <>{props.children}</>;
  return null;
};
