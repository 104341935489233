import axios, { AxiosError } from "axios";
import { PersonDto } from "../components/tree/TreeTypes";
import { API_URL } from "../Constants";
import { FirebaseConfig } from "../FirebaseConfig";
import { AdminStatistics } from "../pages/Admin/Admin";

export interface loginDto {
  username: string;
  password: string;
}

export interface registerPersonDto {
  name: string;
  surname: string;
  birthDay?: string;
  cityOfBirth?: string;
  countryOfBirth?: string;
  gender: "MALE" | "FEMALE" | "UNSET";
  username: string; // include it to associate Person to User (in create/update)
  email: string;
}

export interface registerDto {
  username: string;
  password: string;
  email: string;
}

export const login = async (dto: loginDto) => {
  let resp = await axios
    .post(
      API_URL + "/graph/account/login",
      {
        username: dto.username,
        password: dto.password,
      },
      { headers: { Authorization: null } }
    )
    .catch((error: AxiosError) => {
      return error.response;
    });

  console.log("Response LoginData", resp);
  return resp;
};

export const Admin_login = async (dto: loginDto) => {
  let resp = await axios
    .post(API_URL + "/graph/admin/login", {
      username: dto.username,
      password: dto.password,
    })
    .catch((error: AxiosError) => {
      console.log("Error return data", error);
      return error.response;
    });
  return resp;
};

export const getAdminStatistics = async () => {
  const token = sessionStorage.getItem("token");

  let resp = await axios
    .get(API_URL + "/graph/admin/statistics", {
      headers: { Authorization: "Bearer " + token },
    })
    .catch((error: AxiosError) => {
      return error.response;
    });
  console.log("The response of the Statical data is", resp);
  return resp?.data as AdminStatistics;
};

export const register = async (
  registerDto: registerDto,
  captchaToken: string
) => {
  console.log(registerDto);

  let resp = await axios
    .post(API_URL + "/graph/account/register", registerDto, {
      params: {
        captchaToken,
      },
    })
    .catch((error: AxiosError) => {
      console.error(error);
      return error.response;
    });

  console.log(resp);
  return resp;
};

export const registerWithcode = async (
  personDto: PersonDto,
  inviteId: string,
  captchaToken: string
) => {
  let resp = await axios
    .post(API_URL + "/graph/account/registerByInvitation", personDto, {
      params: {
        inviteId,
        captchaToken,
      },
    })
    .catch((error: AxiosError) => {
      console.error(error);
      return error.response;
    });

  console.log(resp);
  return resp;
};

// used to link the new account to an existing person
// requires username/email/password
export const registerPerson = async (person: PersonDto) => {
  let resp = await axios
    .post(API_URL + "/graph/person/registerPerson", person)
    .catch((e: AxiosError) => e.response);
  console.log(resp);
  return resp?.status === 200;
};

export const createPerson = async (personDto: registerPersonDto) => {
  console.log(personDto);
  let resp = await axios
    .post(API_URL + "/graph/person?createUser=false", personDto)
    .catch((error: AxiosError) => {
      console.error(error);
      return error.response;
    });

  console.log(resp);

  if (resp?.status !== 200) {
    console.error(resp);
    // throw new Error(resp?.statusText)
  }

  return resp;
};

export const resetPassword = async (username: string) => {
  let resp = await axios
    .post(
      API_URL + "/graph/account/resetPassword",
      { username },
      { headers: { Authorization: null } }
    )
    .catch((error: AxiosError) => {
      console.error(error);
      return error.response;
    });

  console.log(resp);

  return resp?.status === 200 ?? false;
};

export const updatePassword = async (
  username: string,
  userId: string,
  password: string
) => {
  let resp = await axios
    .put(API_URL + "/graph/user/updatePwd", { username, userId, password })
    .catch((error: AxiosError) => {
      console.error(error);
      return error.response;
    });

  console.log(resp);

  return resp?.status === 200;
};

export const deleteAccount = async (userId: string) => {
  let resp = await axios
    .delete(API_URL + "/graph/user/deleteUser", { data: { userId } })
    .catch((error: AxiosError) => {
      console.error(error);
      return error.response;
    });

  console.log(resp);

  if (resp?.status === 200) FirebaseConfig.log("user_delete");

  return resp?.status === 200;
};

export const setSafePin = async (
  userId: string,
  pin: string,
  oldPin?: string
) => {
  let resp = await axios
    .post(API_URL + "/graph/person/setSafePin", null, {
      params: {
        userId,
        pin,
        oldPin,
      },
    })
    .catch((error: AxiosError) => {
      console.error(error);
      return error.response;
    });

  console.log(resp);

  return resp?.status === 200;
};

export const validateSafePin = async (userId: string, pin: string) => {
  let resp = await axios
    .post(API_URL + "/graph/person/validateSafePin", null, {
      params: {
        userId,
        pin,
      },
    })
    .catch((error: AxiosError) => {
      console.error(error);
      return error.response;
    });

  console.log(resp);

  return resp?.status === 200 && (resp.data as boolean);
};

export const resetSafePin = async (userId: string) => {
  let resp = await axios
    .post(API_URL + "/graph/person/resetSafePin", null, {
      params: {
        userId,
      },
    })
    .catch((error: AxiosError) => {
      console.error(error);
      return error.response;
    });

  console.log(resp);

  return resp?.status === 200;
};

export const hasDropbox = async (userId: string) => {
  let resp = await axios
    .get(API_URL + "/graph/person/hasDropbox", {
      params: {
        userId,
      },
    })
    .catch((error: AxiosError) => {
      console.error(error);
      return error.response;
    });

  console.log(resp);

  return resp?.status === 200 && (resp.data as boolean);
};

export const hasSafePin = async (userId: string) => {
  let resp = await axios
    .get(API_URL + "/graph/person/hasSafePin", {
      params: {
        userId,
      },
    })
    .catch((error: AxiosError) => {
      console.error(error);
      return error.response;
    });

  console.log(resp);

  return resp?.status === 200 && (resp.data as boolean);
};

export const refreshTokenAPI = async (refreshToken: string) => {
  let resp = await axios
    .post(API_URL + "/graph/account/refresh", null, {
      params: { refreshToken },
      headers: { Authorization: null },
    })
    .catch((error: AxiosError) => {
      return error.response;
    });

  console.log(resp);
  return resp;
};
