import React, { FunctionComponent, useEffect } from "react";
import { Route, RouteProps, Redirect } from "react-router-dom";
import { useAppSelector } from "../store/hooks";
import {
  selectAuthenticated,
  selectHasActiveSubscription,
  selectProfile,
} from "../store/auth/authSlice";
import { ROUTE_HOME, ROUTE_PAYMENTS } from "../Constants";
import { HistoryListener } from "../FirebaseConfig";
import * as H from "history";
export interface LocationState {
  from: H.Location<unknown>;
}
/**
 * Will redirect to "/home" if authenticated
 */
const AuthRoute: FunctionComponent<RouteProps> = ({ component, ...rest }) => {
  const isAuthenticated = useAppSelector(selectAuthenticated);
  const hasProfile = useAppSelector(selectProfile);

  if (!component) return null;

  const Component: React.ElementType = component;

  return (
    <>
      <HistoryListener />
      <Route
        {...rest}
        render={({ location }) => {
          let state: LocationState = { from: location };
          return isAuthenticated && hasProfile ? (
            <Redirect
              to={{
                pathname: ROUTE_HOME,
                state: { from: location },
              }}
            />
          ) : (
            <Component />
          );
        }}
      />
    </>
  );
};

export default AuthRoute;
