import {
  CalendarOutlined,
  EnvironmentOutlined,
  GlobalOutlined,
  HomeOutlined,
  LockOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import { Breadcrumb } from "antd";
import BreadcrumbItem from "antd/lib/breadcrumb/BreadcrumbItem";
import { FunctionComponent, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ROUTE_FILES } from "../../Constants";
import { getLocalizedCountry } from "../../util/GeoUtil";
import { FolderType } from "./FileLayout";

interface BreadProps {}

const Bread: FunctionComponent<BreadProps> = () => {
  const params = new URLSearchParams(window.location.search);
  const safe: boolean = params.get("isProtected") === "true";

  const history = useHistory();

  const [breadcrumbIcon, setBreadcrumbIcon] = useState(<HomeOutlined />);
  const [breadcrumbText, setBreadcrumbText] = useState("");
  const countryBreadcrumbIcon = <EnvironmentOutlined />;
  const [countryBreadcrumbText, setCountryBreadcrumbText] = useState("");

  const [whichModalOpen, setWhichModalOpen] = useState<number | undefined>();

  useEffect(() => {
    if (params.get("isProtected") == "true") {
      setBreadcrumbText("La mia cassaforte");
      setCountryBreadcrumbText("");
    } else if (params.get("annual") == "true") {
      setBreadcrumbText("Mesi");
      setCountryBreadcrumbText("");
    } else if (params.get("shared") == "true") {
      setBreadcrumbText("Condivisi");
      setCountryBreadcrumbText("");
    } else if (params.get("places") == "true") {
      if (params.has("country"))
        setCountryBreadcrumbText(getLocalizedCountry(params.get("country")!));
      else setCountryBreadcrumbText("");
      setBreadcrumbText("Luoghi");
    } else setCountryBreadcrumbText("");

    if (params.get("isProtected") == "true") {
      setWhichModalOpen(FolderType.SAFE_MODAL);
    } else if (params.get("annual") == "true") {
      setWhichModalOpen(FolderType.ANNUAL_MODAL);
    } else if (params.get("places") == "true") {
      setWhichModalOpen(FolderType.PLACES_MODAL);
    } else if (params.get("shared") == "true") {
      setWhichModalOpen(FolderType.SHARED_MODAL);
    } else {
      setWhichModalOpen(FolderType.MODAL);
    }
  }, [params]);

  useEffect(() => {
    if (whichModalOpen == FolderType.SAFE_MODAL)
      setBreadcrumbIcon(<LockOutlined />);
    else if (whichModalOpen == FolderType.ANNUAL_MODAL)
      setBreadcrumbIcon(<CalendarOutlined />);
    else if (whichModalOpen == FolderType.PLACES_MODAL)
      setBreadcrumbIcon(<GlobalOutlined />);
    else if (whichModalOpen == FolderType.SHARED_MODAL)
      setBreadcrumbIcon(<ShareAltOutlined />);
  }, [breadcrumbText]);

  return (
    <div
      className="albumsBreadcrumbs"
      style={{ paddingLeft: 20, paddingRight: 20, paddingBottom: 10 }}
    >
      <Breadcrumb separator=">" style={{ cursor: "pointer" }}>
        <Breadcrumb.Item
          onClick={() => {
            history.push(ROUTE_FILES);
          }}
        >
          {/* style={{ color:COLORS.purple}} */}

          {safe && <LockOutlined />}

          <span style={{ fontWeight: !whichModalOpen ? "bold" : "normal" }}>
            {safe ? "LA MIA CASSAFORTE" : "IL MIO ARCHIVIO"}
          </span>
        </Breadcrumb.Item>
        {whichModalOpen != FolderType.MODAL && (
          <BreadcrumbItem
            onClick={() => {
              if (
                whichModalOpen == FolderType.PLACES_MODAL &&
                countryBreadcrumbText
              )
                /*history.push(ROUTE_FILES + "?places=true")*/

                history.push(ROUTE_FILES + "?places=true");
            }}
          >
            {breadcrumbIcon}
            <span>{breadcrumbText}</span>
          </BreadcrumbItem>
        )}
        {countryBreadcrumbText && (
          <BreadcrumbItem>
            {countryBreadcrumbIcon}
            <span>{countryBreadcrumbText}</span>
          </BreadcrumbItem>
        )}
      </Breadcrumb>
    </div>
  );
};

export default Bread;
