import { Button, Modal } from "antd";
import { FunctionComponent, useState } from "react";
import CookieConsent from "react-cookie-consent";
import { COLORS } from "../Constants";
import Cookies from "js-cookie";
import { FirebaseConfig } from "../FirebaseConfig";

interface CookieManagerProps {}

export const COOKIE_BASE = "acceptedBaseCookies";
const COOKIE_ANALYTICS = "acceptedAnalyicsCookies";

const CookieManager: FunctionComponent<CookieManagerProps> = () => {
  const params = new URLSearchParams(window.location.search);

  const [customizeVisible, setCustomizeVisible] = useState<boolean>(false);

  const [accepted, setAccepted] = useState(
    Cookies.get(COOKIE_BASE) === "true" || params.get("mobile") === "true"
  );

  const onAccepted = (value: boolean) => {
    Cookies.set(COOKIE_ANALYTICS, value.toString(), {
      expires: 365,
    });
    Cookies.set(COOKIE_BASE, "true", {
      expires: 365,
    });
    setCustomizeVisible(false);
    setAccepted(true);
    if (value) {
      FirebaseConfig.initAnalytics();
    }
  };

  let acceptedAnalyicsCookies = Cookies.get(COOKIE_ANALYTICS) === "true";
  if (acceptedAnalyicsCookies) {
    FirebaseConfig.initAnalytics();
  }

  return (
    <>
      <CookieConsent
        location="bottom"
        visible={accepted ? "hidden" : "show"}
        cookieValue={COOKIE_BASE}
        style={{
          zIndex: 1001,
          backgroundColor: COLORS.limeGreen,
          color: COLORS.darkGreen,
        }}
        buttonStyle={{
          color: "white",
          backgroundColor: COLORS.lightGreen,
        }}
        buttonText="Accetta"
        declineButtonStyle={{
          color: "white",
          backgroundColor: COLORS.orange,
          marginRight: 0,
        }}
        declineButtonText="Personalizza"
        enableDeclineButton
        onAccept={() => {
          onAccepted(true);
        }}
        onDecline={() => {
          setCustomizeVisible(true);
        }}
        expires={365}
      >
        Family World utilizza Cookie per finalità tecniche e, con il tuo
        consenso, anche per altre finalità come specificato nella{" "}
        <a href="cookie">privacy policy</a>
      </CookieConsent>

      <Modal visible={customizeVisible} footer={false}>
        <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
          <div>
            Con il tuo consenso, potrai contribuire al miglioramento di Family
            World.
          </div>
          <div>
            I Dati Personali saranno trattati per sole finalità di Statistica e
            miglioramento dell'esperienza utilizzando gli strumenti di Google
            Analytics for Firebase.
          </div>

          <div>
            {" "}
            Dati raccolti: <br />
            - Apertura dell'Applicazione
            <br />
            - Dati di utilizzo
            <br />
            - Durata della sessione
            <br />- Informazioni sul dispositivo
          </div>

          <a href="cookie">Privacy policy completa</a>
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end", gap: 10 }}>
          <Button
            onClick={() => {
              onAccepted(false);
            }}
          >
            Rifiuta
          </Button>
          <Button
            onClick={() => {
              onAccepted(true);
            }}
            type="primary"
          >
            Accetta
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default CookieManager;
