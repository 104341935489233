import { FriendshipRelationInterface, PersonInterface } from "./PersonTypes";

export enum NotificationTypes {
  FRIEND_REQUEST = "FRIEND_REQUEST",
  NEW_POST = "NEW_POST",
  SHARED_FILE = "SHARED_FILE",
  SHARED_ALBUM = "SHARED_ALBUM",
  GRAPH_REQUEST = "GRAPH_REQUEST",
}

type NotificationStatusType = "RECEIVED" | "ACCEPTED" | "REJECTED";
interface GenericNotificationInterface {
  notificationId: string;
  sender: PersonInterface;
  notificationStatus: NotificationStatusType;
  message: string;
  creationDate: string;
  read: boolean;
  loading?: boolean;
}

export interface GraphRequestNotificationInterface
  extends GenericNotificationInterface {
  notificationType: NotificationTypes.GRAPH_REQUEST;
}

export interface PostNotificationInterface
  extends GenericNotificationInterface {
  notificationType: NotificationTypes.NEW_POST;
  postId: string;
  feedId: string;
  groupId?: string | null;
}

export interface SharingNotificationInterface
  extends GenericNotificationInterface {
  notificationType: NotificationTypes.SHARED_FILE;
  fileId: string;
}

export interface AlbumSharingNotificationInterface
  extends GenericNotificationInterface {
  notificationType: NotificationTypes.SHARED_ALBUM;
  albumId: string;
}

export interface FriendRequestNotificationInterface
  extends GenericNotificationInterface {
  notificationType: NotificationTypes.FRIEND_REQUEST;
  relationship: FriendshipRelationInterface;
  startingDate: string;
  accepted: boolean;
}

export type NotificationInterface =
  | SharingNotificationInterface
  | PostNotificationInterface
  | GraphRequestNotificationInterface
  | FriendRequestNotificationInterface
  | AlbumSharingNotificationInterface;
