import React, { useCallback, useEffect, useState } from "react";
import { ROUTE_FILES } from "../Constants";
import { useHistory } from "react-router-dom";
import { saveDropboxCode } from "../service/userAPI";
import { useAppSelector } from "../store/hooks";
import { selectProfile } from "../store/auth/authSlice";

const OAuth2Page: React.FunctionComponent = () => {
  const history = useHistory();
  const personId = useAppSelector(selectProfile)?.personId!;

  const params = new URLSearchParams(window.location.search);

  const oauthCode = params.get("code");

  useEffect(() => {
    if (oauthCode) {
      // send code to backend
      console.log(oauthCode);

      saveDropboxCode(oauthCode, personId).then(() => {
        // redirect to files
        history.push(ROUTE_FILES);
      });
    }
  }, [oauthCode]);

  useEffect(() => {
    setTimeout(() => {
      history.push(ROUTE_FILES);
    }, 10000);
  }, []);
  return <div>Sto caricando</div>;
};

export default OAuth2Page;
