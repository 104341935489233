import colors from "./style/colors.module.scss";

const PROD_URL = "https://www.familyworld.it";

export const TREE_LAYOUT_CHANGE = false;

const TEST_ON_LOCAL: boolean =
  (process.env.REACT_APP_ENV as string) === "local";

export const PRODUCTION_MODE = !TEST_ON_LOCAL && true;

export const TEST_ON_DEV: boolean = true;
const TEST_ON_PROD: boolean = false;

export const DEBUG_TREE_GENERATOR = false;

// APIs
export const API_URL = PROD_URL + "/api";

export const TREE_GENERATOR_URL = PROD_URL + "/layout";

export const MOBILE_APP_URL = "/mobile";

// app routes
export const ROUTE_BASE = "/",
  ROUTE_HOME = "/home",
  ROUTE_LANDING = "/landing",
  ROUTE_LOGIN = "/login",
  ROUTE_REGISTRATION = "/registration",
  ROUTE_TREE = "/tree",
  ROUTE_PEOPLES_LIST = "/list",
  ROUTE_PERSON_VIEW = "/person/:id?",
  ROUTE_FILES = "/files",
  ROUTE_OAUTH_RESPONSE = "/oauth/response",
  ROUTE_FOLDER = "/folder/:id",
  ROUTE_SHARED_FOLDER = "/folder/shared",
  ROUTE_ADMIN = "/admin",
  ROUTE_FEED = "/feed",
  ROUTE_PROFILE = "/profile",
  ROUTE_PAYMENTS = "/subscription",
  ROUTE_DESK = "/desk",
  ROUTE_HELP = "/help",
  ROUTE_SHARE = "/share",
  ROUTE_FULL_FAMILY = "/family/:id?",
  ROUTE_ANCESTORS = "/ancestors/:id?",
  ROUTE_FEED_DETAIL = "/feed/:context";

// colors
export const COLORS = {
  blue: colors.blue,
  red: colors.red,
  purple: colors.purple,
  purpleAlpha: colors.purpleAlpha,

  blueDark: colors.blueDark,
  redDark: colors.redDark,
  purpleDark: colors.purpleDark,
  cyan: colors.cyan,
  lightCyan: colors.lightCyan,
  darkCyan: colors.darkCyan,
  orange: colors.orange,
  lightOrange: colors.lightOrange,
  darkOrange: colors.darkOrange,
  peach: colors.peach,

  lightGreen: colors.lightGreen,
  lightGreenAlpha: colors.lightGreenAlpha,
  darkGreen: colors.darkGreen,
  neonGreen: colors.neonGreen,
  limeGreen: colors.limeGreen,
  pink: colors.pink,
  pea: colors.pea,
  yellow: colors.yellow,
  orangeAlpha: colors.orangeAlpha,
};

export const MAX_UPLOAD_SIZE = 1048576 * 100; // 100 MB
