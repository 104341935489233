import "./style/style.scss";
import "antd/dist/antd.css";

import { MainRouter } from "./router/MainRouter";
import AxiosManager from "./components/AxiosManager";
import { Localize } from "./Localizer";
import CookieManager from "./util/CookieManager";
import FirebaseManager from "./FirebaseManager";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <Localize>
      <AxiosManager />
      <FirebaseManager />
      <MainRouter />
      <CookieManager />
      <ToastContainer />
    </Localize>
  );
}

export default App;
