import { Switch, Route, BrowserRouter } from "react-router-dom";
import {
  ROUTE_BASE,
  ROUTE_DESK,
  ROUTE_FEED,
  ROUTE_FEED_DETAIL,
  ROUTE_FILES,
  ROUTE_FOLDER,
  ROUTE_HELP,
  ROUTE_HOME,
  ROUTE_LOGIN,
  ROUTE_PAYMENTS,
  ROUTE_FULL_FAMILY,
  ROUTE_PERSON_VIEW,
  ROUTE_PROFILE,
  ROUTE_REGISTRATION,
  ROUTE_ADMIN,
  ROUTE_TREE,
  ROUTE_PEOPLES_LIST,
  ROUTE_ANCESTORS,
  ROUTE_SHARE,
  ROUTE_OAUTH_RESPONSE,
} from "../Constants";
import PageNotFound from "../pages/PageNotFound";
import AuthRoute from "./AuthRoute";
import PrivateRoute from "./PrivateRoute";
import { lazy, Suspense } from "react";
import SharePage from "../pages/SharePage";
import OAuth2Page from "../pages/OAuth2Page";

export const MainRouter: React.FunctionComponent = () => {
  const Tree = lazy(() => import("../pages/Tree"));
  const LandingPage = lazy(() => import("../pages/LandingPage"));
  const Home = lazy(() => import("../pages/Home"));
  const Files = lazy(() => import("../pages/Files"));
  const Folder = lazy(() => import("../pages/Folder"));
  const Feed = lazy(() => import("../pages/Feed"));
  const PersonView = lazy(() => import("../components/person_view/PersonView"));
  const List = lazy(() => import("../components/List/listview"));
  const Profile = lazy(() => import("../pages/Profile"));
  const SubscriptionPage = lazy(
    () => import("../pages/payment/SubscriptionPage")
  );
  const HelpFormPage = lazy(() => import("../pages/HelpFormPage"));
  const AncestorsTree = lazy(
    () => import("../components/family_tree/LayoutTree")
  );
  const Design_familytree = lazy(
    () => import("../components/design_layout/design_familytree")
  );
  const Admin = lazy(() => import("../pages/Admin/Admin"));

  return (
    <BrowserRouter>
      <Suspense fallback={null}>
        <Switch>
          <AuthRoute exact path={ROUTE_BASE} component={LandingPage} />
          <AuthRoute path={ROUTE_LOGIN} component={LandingPage} />
          <AuthRoute path={ROUTE_REGISTRATION} component={LandingPage} />

          <PrivateRoute path={ROUTE_FOLDER} component={Folder} />
          <PrivateRoute path={ROUTE_HOME} component={Home} />
          <PrivateRoute path={ROUTE_TREE} component={Tree} />
          <PrivateRoute path={ROUTE_PERSON_VIEW} component={PersonView} />
          <PrivateRoute path={ROUTE_PEOPLES_LIST} component={List} />
          <PrivateRoute path={ROUTE_FILES} component={Files} />

          <PrivateRoute path={ROUTE_OAUTH_RESPONSE} component={OAuth2Page} />

          <PrivateRoute path={ROUTE_FEED} component={Feed} exact />
          <PrivateRoute path={ROUTE_PROFILE} component={Profile} exact />
          <PrivateRoute
            path={ROUTE_PAYMENTS}
            component={SubscriptionPage}
            exact
          />
          <PrivateRoute path={ROUTE_FEED_DETAIL} component={Feed} />
          <Route path={ROUTE_HELP} component={HelpFormPage} />
          <Route path={ROUTE_SHARE} component={SharePage} />

          <PrivateRoute path={ROUTE_ANCESTORS} component={AncestorsTree} />
          <Route path={ROUTE_ADMIN} component={Admin} />
          <PrivateRoute
            path={ROUTE_FULL_FAMILY}
            component={Design_familytree}
          />
          <PrivateRoute path={ROUTE_BASE} component={PageNotFound} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};
