import axios from "axios";
import { FileDto } from "../components/files/Types";
import { API_URL } from "../Constants";
import { AlbumInterface } from "../types/AlbumTypes";

const CREATE_ALBUM_URL = API_URL + "/graph/album/createAlbum"; //POST
const DELETE_ALBUM_URL = API_URL + "/graph/album/deleteAlbum"; //DELETE
const RETRIEVE_ALBUM_URL = API_URL + "/graph/album/retrieveAlbum "; //GET
const ADD_FILE_TO_ALBUM_URL = API_URL + "/graph/album/addFile"; //PUT
const REMOVE_FILE_FROM_ALBUM_URL = API_URL + "/graph/album/removeFile "; //PUT
const RENAME_ALBUM_URL = API_URL + "/graph/album/renameAlbum"; //PUT
const RETRIEVE_USER_ALBUMS_URL = API_URL + "/graph/album/getAllUserAlbum"; //GET
const FIND_ANNUAL_ALBUMS = API_URL + "/graph/file/findAnnualAlbums"; //GET
const SHARE_ALBUMS = API_URL + "/graph/album/shareAlbums"; //POST
const RETRIEVE_SHARED_ALBUM_URL = API_URL + "/graph/album/retrieveSharedAlbum"; //GET
const SHARE_ALBUM_URL = API_URL + "/graph/album/shareAlbum"; //POST
const UNSHARE_ALBUM_URL = API_URL + "/graph/album/unshareAlbum"; //POST
const FIND_FILES_PLACES = API_URL + "/graph/file/findFilesPlaces"; //GET

export const createAlbumAPI = async (
  creatorId: string,
  name: string,
  files: FileDto[] = []
): Promise<AlbumInterface> => {
  return (
    await axios.post(
      CREATE_ALBUM_URL,
      { name, files },
      { params: { creatorId } }
    )
  ).data;
};

export const deleteAlbumAPI = async (
  creatorId: string,
  albumId: string
): Promise<void> => {
  return await axios.delete(DELETE_ALBUM_URL, {
    params: { userId: creatorId, albumId },
  });
};

export const retrieveAlbumAPI = async (
  creatorId: string,
  albumId: string
): Promise<AlbumInterface> => {
  return (
    await axios.get(RETRIEVE_ALBUM_URL, {
      params: { creatorId, albumId },
    })
  ).data;
};

export const addFileToAlbumAPI = async (
  creatorId: string,
  albumId: string,
  fileIds: (number | string)[]
): Promise<void> => {
  return await axios.put(ADD_FILE_TO_ALBUM_URL, null, {
    params: { creatorId, albumId, fileIds },
  });
};

export const removeFileFromAlbumAPI = async (
  creatorId: string,
  albumId: string,
  fileIds: (number | string)[]
): Promise<void> => {
  return await axios.put(REMOVE_FILE_FROM_ALBUM_URL, null, {
    params: { creatorId, albumId, fileIds },
  });
};

export const renameAlbumAPI = async (
  creatorId: string,
  albumId: string,
  name: string
): Promise<AlbumInterface> => {
  return (
    await axios.put(RENAME_ALBUM_URL, null, {
      params: { userId: creatorId, albumId, name },
    })
  ).data;
};

export const retrieveUserAlbumsAPI = async (
  userId: string
): Promise<AlbumInterface[]> => {
  return (
    await axios.get(RETRIEVE_USER_ALBUMS_URL, {
      params: { userId },
    })
  ).data;
};

export const findAnnualAlbumsAPI = async (uploader: string) => {
  const res = await axios.get(FIND_ANNUAL_ALBUMS, {
    params: { uploader },
  });

  console.log(res);
  return res.data as Map<string, number[]>;
};
export const shareAlbumsAPI = async (
  userId: string,
  albumIds: string[],
  groupId?: string
): Promise<string> => {
  return (
    await axios.post(SHARE_ALBUMS, albumIds, { params: { userId, groupId } })
  ).data;
};

export const retrieveSharedAlbumAPI = async (
  userId: string
): Promise<AlbumInterface[]> => {
  return (
    await axios.get(RETRIEVE_SHARED_ALBUM_URL, {
      params: { userId },
    })
  ).data;
};

export const shareAlbumAPI = async (
  userId: string,
  albumId: string,
  groupIds?: string[]
): Promise<string> => {
  return (
    await axios.post(SHARE_ALBUM_URL, groupIds, { params: { userId, albumId } })
  ).data;
};

export const unshareAlbumAPI = async (
  userId: string,
  albumId: string,
  groupIds: string[]
): Promise<string> => {
  return await axios.delete(UNSHARE_ALBUM_URL, {
    params: { userId, albumId },
    data: groupIds,
  });
};

export const findFilesPlacesAPI = async (uploader: string) => {
  const res = await axios.get(FIND_FILES_PLACES, {
    params: { uploader },
  });

  console.log(res);
  return res.data as Map<string, string[]>;
};
