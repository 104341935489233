import axios from "axios";
import { API_URL } from "../Constants";
import { Status } from "../store/tour/tourSlice";

const LAST_TUTORIAL_VERSION = 1;

export const sendTourCompleted = async (
  userId: string,
  tourKey: string,
  version: number
) => {
  await axios.post(API_URL + "/graph/tour/complete", null, {
    params: {
      userId,
      tourKey,
      version,
      mobile: false,
    },
  });
};

export const getTourStatus = async (userId: string) => {
  let resp = await axios.get(API_URL + "/graph/tour/status", {
    params: {
      userId,
      mobile: false,
    },
  });

  return resp.data as Status;
};
