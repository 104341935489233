import {
  ArrowRightOutlined,
  CalendarOutlined,
  CheckCircleFilled,
  DropboxOutlined,
  EnvironmentOutlined,
  GlobalOutlined,
  HomeOutlined,
  LockFilled,
  LockOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Form,
  Input,
  message,
  Switch,
  Tooltip,
} from "antd";
import BreadcrumbItem from "antd/lib/breadcrumb/BreadcrumbItem";
import Modal from "antd/lib/modal/Modal";
import React, { useEffect } from "react";
import { FunctionComponent, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { COLORS, ROUTE_FILES, ROUTE_FOLDER } from "../../Constants";
import { DUMMY_ALBUM_IDS } from "../../pages/Folder";
import {
  hasDropbox,
  hasSafePin,
  resetSafePin,
  setSafePin,
  validateSafePin,
} from "../../service/authAPI";
import { selectProfile } from "../../store/auth/authSlice";
import { useAppSelector } from "../../store/hooks";
import { getLocalizedCountry } from "../../util/GeoUtil";
import useFocus from "../../util/useFocus";
import { dropboxRedirectUri } from "../../pages/Files";

export enum FolderType {
  MODAL = 0,
  SAFE_MODAL = 1,
  ANNUAL_MODAL = 2,
  PLACES_MODAL = 3,
  SHARED_MODAL = 4,
  DROPBOX = 5,
  SCRIVANIA = 6,
}
export interface FileLayoutProps {
  title: string;
  height: string | number;
  expanded: boolean;
  tempAlbumId?: string;
}

export const FileLayout: React.FunctionComponent<FileLayoutProps> = (props) => {
  const history = useHistory();

  const [safeModalOpen, setsafeModalOpen] = useState(false);
  const [whichModalOpen, setWhichModalOpen] = useState<number | undefined>();

  const profile = useAppSelector(selectProfile);
  const params = new URLSearchParams(window.location.search);

  useEffect(() => {
    if (params.get("isProtected") == "true") {
      setWhichModalOpen(FolderType.SAFE_MODAL);
    } else if (params.get("annual") == "true") {
      setWhichModalOpen(FolderType.ANNUAL_MODAL);
    } else if (params.get("places") == "true") {
      setWhichModalOpen(FolderType.PLACES_MODAL);
    } else if (params.get("shared") == "true") {
      setWhichModalOpen(FolderType.SHARED_MODAL);
    } else {
      setWhichModalOpen(FolderType.MODAL);
    }
  }, [params]);

  const openSafe = () => {
    setsafeModalOpen(false);
    setWhichModalOpen(FolderType.SAFE_MODAL);
    history.push(ROUTE_FILES + "?isProtected=true");
  };

  const openFiles = () => {
    setsafeModalOpen(false);
    setWhichModalOpen(FolderType.MODAL);
    history.push(ROUTE_FILES);
  };

  const showSafeModal = () => {
    setsafeModalOpen(true);
    setWhichModalOpen(FolderType.SAFE_MODAL);
  };

  const openAnnualFiles = () => {
    setsafeModalOpen(false);
    setWhichModalOpen(FolderType.ANNUAL_MODAL);
    history.push(ROUTE_FILES + "?annual=true");
  };

  const openPlacesFiles = () => {
    setsafeModalOpen(false);
    setWhichModalOpen(FolderType.PLACES_MODAL);
    history.push(ROUTE_FILES + "?places=true");
  };

  const openScrivaniaFiles = () => {
    setsafeModalOpen(false);
    if (props.tempAlbumId)
      history.push(
        ROUTE_FOLDER.replace(":id", props.tempAlbumId) +
          "?page=1&filesPerPage=5"
      );
  };

  const openSharedFiles = () => {
    setsafeModalOpen(false);
    setWhichModalOpen(FolderType.SHARED_MODAL);
    history.push(ROUTE_FILES + "?shared=true");
  };

  const openAllFiles = () => {
    setsafeModalOpen(false);
    history.push(
      ROUTE_FOLDER.replace(":id", DUMMY_ALBUM_IDS.ALL) +
        "?page=1&filesPerPage=5"
    );
  };

  const goHome = () => {
    setsafeModalOpen(false);
    setWhichModalOpen(undefined);

    history.push(ROUTE_FILES);
  };

  const onOpenDropbox = async () => {
    let dropbox = await hasDropbox(profile?.personId!);
    if (!dropbox)
      window.location.replace(
        `https://www.dropbox.com/oauth2/authorize?client_id=zhvap0tfjyq43lb&redirect_uri=${dropboxRedirectUri}&response_type=code&token_access_type=offline`
      );
    else {
      history.push(ROUTE_FILES + "?dropbox=true");
    }
  };

  return (
    <div className={`files-section ${props.expanded ? "open" : ""}`}>
      <SectionSelector
        onOpenSafe={showSafeModal}
        onOpenFiles={openFiles}
        onOpenAnnualFiles={openAnnualFiles}
        onOpenPlacesFiles={openPlacesFiles}
        onOpenSharedFiles={openSharedFiles}
        onOpenScrivaniaFiles={openScrivaniaFiles}
        onOpenAllFiles={openAllFiles}
        onGoHome={goHome}
        onOpenDropbox={onOpenDropbox}
        safeModalOpen={safeModalOpen}
        whichModalOpen={whichModalOpen!}
      />

      {/* <div style={{ height: 20, margin: 10 }}></div> */}

      {safeModalOpen && (
        <SafeOverlay
          openSafe={openSafe}
          cancel={() => {
            setsafeModalOpen(false);
          }}
        >
          {props.children}
        </SafeOverlay>
      )}
      {props.children}
    </div>
  );
};

interface SafeOverlayProps {
  openSafe: () => void;
  cancel: () => void;
}

const SafeOverlay: FunctionComponent<SafeOverlayProps> = (props) => {
  const [checked, setchecked] = useState(true);
  const [showInput, setshowInput] = useState(false);
  const [input, setinput] = useState<string>("");
  const [safeChangeModalVisible, setsafeChangeModalVisible] = useState(false);
  const [safeResetModalVisible, setsafeResetModalVisible] = useState(false);

  const [oldPin, setoldPin] = useState<string>("");
  const [newPin, setnewPin] = useState<string>("");
  const [newPinConfirm, setnewPinConfirm] = useState<string>("");
  const [hasPin, sethasPin] = useState(true);
  const inputRef = React.useRef<any>(null);

  const userId = useAppSelector(selectProfile)?.personId;

  const openSafePinDialog = () => {
    setsafeChangeModalVisible(true);
  };

  useEffect(() => {
    hasSafePin(userId!).then((val) => {
      sethasPin(val);
    });
  }, []);

  const onSubmit = async () => {
    if (hasPin) {
      validateSafePin(userId!, input).then((res) => {
        if (!res)
          toast.error("PIN non valido", {
            position: "bottom-right",
            hideProgressBar: true,
          });
        else props.openSafe();
      });
    } else {
      setSafePin(userId!, input).then((res) => {
        if (!res) return;
        toast.success("PIN impostato", {
          position: "bottom-right",
          hideProgressBar: true,
        });
        props.openSafe();
      });
    }
  };

  const onChangePin = () => {
    if (!oldPin || !newPin || !newPinConfirm) {
      toast.error("Compila tutti i campi!");
      return;
    } else if (newPin !== newPinConfirm) {
      toast.error("I valori inseriti non corrispondono", {
        position: "bottom-right",
        hideProgressBar: true,
      });
      return;
    }
    setSafePin(userId!, newPin, oldPin).then((v) => {
      if (v)
        toast.success("PIN cambiato!", {
          position: "bottom-right",
          hideProgressBar: true,
        });
      else
        toast.error("C'è stato un errore", {
          position: "bottom-right",
          hideProgressBar: true,
        });
      setsafeChangeModalVisible(false);
    });
  };

  const onResetPin = () => {
    resetSafePin(userId!).then((v) => {
      if (v)
        toast.success("PIN ripristinato, controlla la tua email!", {
          position: "bottom-right",
          hideProgressBar: true,
        });
      else
        toast.error("C'è stato un errore", {
          position: "bottom-right",
          hideProgressBar: true,
        });
      setsafeResetModalVisible(false);
    });
  };

  return (
    <div
      style={{
        position: "absolute",
        width: "100%",
        height: "calc(100% - 105px)",
        top: 105,
        zIndex: 100,
        backdropFilter: "blur(3px)",
        backgroundColor: "rgba(78,130,117,0.7)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: 20,
      }}
    >
      <Modal
        visible={safeResetModalVisible}
        onCancel={() => {
          setsafeResetModalVisible(false);
        }}
        onOk={onResetPin}
        title="Reset PIN Cassaforte"
        cancelText="Annulla"
      >
        <div>
          <div>Clicca "OK" per ripristinare il PIN della cassaforte.</div>
          <div>
            Un codice provvisorio verrà inviato alla tua email, potrai usarlo
            per accedere oppure cambiarlo
          </div>
        </div>
      </Modal>
      <Modal
        visible={safeChangeModalVisible}
        onCancel={() => {
          setsafeChangeModalVisible(false);
        }}
        onOk={onChangePin}
        title="Cambio PIN Cassaforte"
        cancelText="Annulla"
      >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "150px auto",
            gap: 10,
          }}
        >
          <div>Vecchio PIN</div>
          <Input
            placeholder="Vecchio PIN"
            value={oldPin}
            onChange={(e) => {
              setoldPin(e.target.value);
            }}
          />

          <div>Nuovo PIN</div>
          <Input
            placeholder="Nuovo PIN"
            value={newPin}
            onChange={(e) => {
              setnewPin(e.target.value);
            }}
          />

          <div>Conferma nuovo PIN</div>
          <Input
            placeholder="Conferma nuovo PIN"
            value={newPinConfirm}
            onChange={(e) => {
              setnewPinConfirm(e.target.value);
            }}
          />
        </div>
      </Modal>

      <img src="/assets/images/safe.png" width={100} />
      <Switch
        className="safe-lock-switch"
        checked={checked}
        onChange={(v) => {
          if (checked) setchecked(v);
          inputRef?.current?.focus();
        }}
        checkedChildren={
          <div
            style={{
              display: "flex",
              width: 100,
              alignItems: "center",
              height: 36,
              paddingLeft: 20,
            }}
          >
            Accesso
            <div
              style={{
                position: "absolute",
                right: 5,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CheckCircleFilled
                style={{
                  fontSize: 30,
                  color: COLORS.lightGreen,
                }}
              />
            </div>
          </div>
        }
        unCheckedChildren={
          <div
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              height: 36,
              paddingLeft: 20,
            }}
            onClick={() => {
              setshowInput(true);
              inputRef?.current?.focus();
            }}
          >
            <div
              style={{
                position: "absolute",
                left: 5,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: COLORS.lightGreen,
                borderRadius: 100,
                width: 30,
                height: 30,
              }}
            >
              <LockOutlined
                style={{
                  fontSize: 18,
                  color: "white",
                }}
              />
            </div>

            <Input
              autoFocus
              placeholder={hasPin ? " Inserisci il tuo pin" : " Imposta un PIN"}
              ref={inputRef}
              style={{ paddingRight: 10 }}
              value={input}
              onPressEnter={() => {
                onSubmit();
              }}
              onChange={(e) => {
                setinput(e.target.value);
              }}
            />
            <ArrowRightOutlined
              style={{ padding: 5, cursor: "pointer" }}
              onClick={onSubmit}
            />
          </div>
        }
      />
      <div style={{ color: "white", display: "flex", gap: 10 }}>
        Hai dimenticato i tuoi dati di accesso?{" "}
        <div
          style={{ cursor: "pointer", textDecoration: "underline" }}
          onClick={() => {
            setsafeResetModalVisible(true);
          }}
        >
          Clicca qui
        </div>
      </div>

      <div
        style={{
          color: "white",
          cursor: "pointer",
          textDecoration: "underline",
        }}
        onClick={() => {
          setsafeChangeModalVisible(true);
        }}
      >
        Cambio PIN
      </div>

      <div
        style={{ color: "white", padding: 10, cursor: "pointer" }}
        onClick={() => {
          props.cancel();
        }}
      >
        Annulla
      </div>
    </div>
  );
};

interface SectionSelectorProps {
  onOpenSafe: () => void;
  onOpenFiles: () => void;
  onOpenAnnualFiles: () => void;
  onOpenPlacesFiles: () => void;
  onOpenSharedFiles: () => void;
  onOpenScrivaniaFiles: () => void;
  onOpenAllFiles: () => void;
  onGoHome: () => void;
  onOpenDropbox: () => void;
  safeModalOpen: boolean;
  whichModalOpen: number | undefined;
}

const SectionSelector: FunctionComponent<SectionSelectorProps> = ({
  onOpenSafe,
  onOpenFiles,
  onOpenAnnualFiles,
  onOpenScrivaniaFiles,
  onOpenPlacesFiles,
  onOpenSharedFiles,
  onOpenAllFiles,
  onOpenDropbox,
  onGoHome,
  safeModalOpen,
  whichModalOpen,
}) => {
  const params = new URLSearchParams(window.location.search);
  const safe: boolean = params.get("isProtected") === "true";

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginBottom: 20,
        height: "fit-content",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          paddingLeft: 10,
          paddingTop: 10,

          gap: 20,
        }}
      >
        {/* <Button
          shape="round"
          onClick={onOpenFiles}
          className={whichModalOpen == FolderType.MODAL ? "toggled" : undefined}
        >
          I miei documenti
      </Button> */}
        <Button
          className={
            whichModalOpen === 0 || whichModalOpen === undefined
              ? "toggled circle"
              : "circle"
          }
          shape="circle"
          onClick={onGoHome}
        >
          <HomeOutlined />
        </Button>
        <Button shape="round" onClick={onOpenAllFiles}>
          Tutti
        </Button>
        <Button
          shape="round"
          onClick={onOpenScrivaniaFiles}
          className={
            whichModalOpen == FolderType.SCRIVANIA ? "toggled" : undefined
          }
        >
          Scrivania
        </Button>
        <Button
          shape="round"
          onClick={onOpenAnnualFiles}
          className={
            whichModalOpen == FolderType.ANNUAL_MODAL ? "toggled" : undefined
          }
        >
          Mesi
        </Button>

        <Button
          shape="round"
          onClick={onOpenPlacesFiles}
          className={
            whichModalOpen == FolderType.PLACES_MODAL ? "toggled" : undefined
          }
        >
          Luoghi
        </Button>
        <Button
          shape="round"
          onClick={onOpenSharedFiles}
          className={
            whichModalOpen == FolderType.SHARED_MODAL ? "toggled" : undefined
          }
        >
          Condivisi
        </Button>
        <Button
          shape="round"
          onClick={onOpenSafe}
          className={[
            "safe-btn",
            (safe || safeModalOpen) && whichModalOpen == FolderType.SAFE_MODAL
              ? "toggled"
              : undefined,
          ].join(" ")}
        >
          La mia Cassaforte
        </Button>

        {/**
 *  <Button
          shape="round"
          className={
            whichModalOpen == FolderType.DROPBOX ? "toggled" : undefined
          }
          onClick={onOpenDropbox}
        >
          <DropboxOutlined />
          Dropbox
        </Button>
 */}
      </div>
    </div>
  );
};

export default SectionSelector;
