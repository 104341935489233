import React from "react";
import { List, Avatar, Badge } from "antd";
import { PersonDto } from "../tree/TreeTypes";
import {
  CloseCircleFilled,
  DownCircleFilled,
  PlusCircleFilled,
  ShareAltOutlined,
  UpCircleFilled,
} from "@ant-design/icons";
import { GroupMemberDto } from "../../types/GroupTypes";

export interface ListPersonDto extends PersonDto {
  selected?: boolean;
  admin?: boolean;
  hideActions?: boolean;
  shared?: boolean;
}

interface PeopleListProps {
  people: ListPersonDto[];
  selectPerson?: (p: ListPersonDto, index: number) => void;
  unselectPerson?: (p: ListPersonDto, index: number) => void;
  promotePerson?: (p: ListPersonDto, index: number) => void;
  demotePerson?: (p: ListPersonDto, index: number) => void;
  unshareFile?: (p:ListPersonDto,  index: number) => void;
  showActions?: boolean;
}

const PeopleList: React.FunctionComponent<PeopleListProps> = ({
  people,
  selectPerson,
  unselectPerson,
  promotePerson,
  demotePerson,
  unshareFile,
  showActions = true,
}) => {
  return (
    <List
      size="small"
      style={{ overflowY: "scroll", maxHeight: 300 }}
      dataSource={people}
      renderItem={(item, i) => {
        const actions = [];
        if (item.admin)
          actions.push(<span className="admin-badge">Admin</span>);
        if (showActions && !item.hideActions) {
          if (promotePerson && demotePerson) {
            actions.push(
              item.admin ? (
                <button
                  className="no-style-btn"
                  onClick={() => demotePerson && demotePerson(item, i)}
                  type="button"
                >
                  <DownCircleFilled
                    color="#1f342f"
                    style={{ fontSize: 18, color: "#1f342f" }}
                  />
                </button>
              ) : (
                <button
                  className="no-style-btn"
                  onClick={() => promotePerson && promotePerson(item, i)}
                  type="button"
                >
                  <UpCircleFilled
                    color="#4f8376"
                    style={{ fontSize: 18, color: "#4f8376" }}
                  />
                </button>
              )
            );
          }

          if(item.shared) {
            actions.push(
              <button
                className="no-style-btn"
                onClick={() => unshareFile && unshareFile(item, i)}
                type="button"
              >
                <ShareAltOutlined
                  style={{ fontSize: 18, color: "#1fc74c" }}
                />
              </button>
            );
          }

          actions.push(
            item.selected ? (
              <button
                className="no-style-btn"
                onClick={() => unselectPerson && unselectPerson(item, i)}
                type="button"
              >
                <CloseCircleFilled
                  color="#ff6060"
                  style={{ fontSize: 18, color: "#ff6060" }}
                />
              </button>
            ) : (
              <button
                className="no-style-btn"
                onClick={() => selectPerson && selectPerson(item, i)}
                type="button"
              >
                <PlusCircleFilled style={{ fontSize: 18, color: "#1fc74c" }} />
              </button>
            )
          );
        }

        return (
          <List.Item
            actions={actions}
            className={"person-item " + (item.selected ? "active" : "inactive")}
          >
            <List.Item.Meta
              avatar={<Avatar src={item.pictureUri} />}
              title={item.name + " " + item.surname}
            />
          </List.Item>
        );
      }}
    />
  );
};

export default PeopleList;

export const parseAvailableMembersToList = (
  members: PersonDto[] = [],
  userId?: string,
  allSelected?: boolean
): ListPersonDto[] =>
  members.map((p) => ({
    ...p,
    selected: !!allSelected,
    admin: false,
    hideActions: !!userId && userId == p.personId,
  }));

export const parseGroupMembersToList = (
  members: GroupMemberDto[],
  userId?: string,
  allSelected?: boolean
): ListPersonDto[] =>
  members.map((p) => ({
    ...p,
    selected: !!allSelected,
    hideActions: !!userId && userId == p.personId,
  }));
