import axios, { CancelToken, CancelTokenSource } from "axios";
import { PersonDto } from "../components/tree/TreeTypes";
import { API_URL } from "../Constants";
import { CommentInterface, PostInterface } from "../types/PostTypes";
import _ from "lodash";

const ADD_POST_URL = API_URL + "/graph/feed/addPost";
const DELETE_POST_URL = API_URL + "/graph/feed/deletePost";

const RETRIEVE_BROADCAST_POSTS_URL = API_URL + "/graph/feed/retrieveFeed";
const RETRIEVE_GROUP_POSTS_URL = API_URL + "/graph/feed/retrieveGroupFeed";
const RETIREVE_ONE_TO_ONE_POSTS_URL = API_URL + "/graph/feed/retrieveGroupFeed";
const RETRIEVE_POST_URL = API_URL + "/graph/feed/retrievePost";
const ADD_COMMENT_URL = API_URL + "/graph/feed/addComment";
const SAVE_POST_URL = API_URL + "/graph/feed/savePost";
const UNSAVE_POST_URL = API_URL + "/graph/feed/unsavePost";
const RETRIEVE_SAVED_POSTS_URL = API_URL + "/graph/feed/favouritePost";
const ADD_MULTIPLE_POST_URL = API_URL + "/graph/feed/addMultiplePost";

export interface TextPost {
  text: string;
  attachments?: { fileId: string }[];
  groupId?: string;
}
export interface AttachmentPost {
  text?: string;
  attachments: { fileId: string }[];
  groupId?: string;
}

export interface AttachmentMultiplePost {
  text?: string;
  attachments: { fileId: string }[];
  groupIds?: string[];
}

export type PostCreationInterface = TextPost | AttachmentPost;
export type MultiplePostCreationInterface = AttachmentMultiplePost;

export const addPostAPI = async (
  creatorId: string,
  post: PostCreationInterface,
  file?: File
): Promise<PostInterface> => {
  const body = _.pick(post, ["text", "attachments"]);
  const formData = new FormData();

  formData.append("creatorId", creatorId);
  if (post.groupId) formData.append("groupId", post.groupId);
  formData.append("post", JSON.stringify(body));
  if (file) formData.append("file", file);
  return (
    await axios.post(ADD_POST_URL, formData, {
      headers: {
        "content-type": "multipart/form-data",
      },
    })
  ).data;
};

export const addMultiplePostAPI = async (
  creatorId: string,
  post: MultiplePostCreationInterface,
  groupIds: string[],
  file?: File
): Promise<string> => {
  const body = _.pick(post, ["text", "attachments"]);
  const formData = new FormData();

  formData.append("creatorId", creatorId);
  formData.append("post", JSON.stringify(body));
  formData.append("groupIds", JSON.stringify(groupIds));
  if (file) formData.append("file", file);

  return await axios.post(ADD_MULTIPLE_POST_URL, formData, {
    headers: {
      "content-type": "multipart/form-data",
    },
  });
};

export const deletePost = async (postId: string) => {
  let res = await axios.delete(DELETE_POST_URL, {
    params: {
      postId,
    },
  });

  return res.status === 200;
};

export const retrievePostsByGroupAPI = async (
  userId: string,
  groupId?: string,
  skip?: number,
  limit?: number,
  cancelToken?: CancelTokenSource
): Promise<PostInterface[]> => {
  let res;
  if (groupId == undefined) return [];
  else
    res = await axios.get(RETRIEVE_GROUP_POSTS_URL, {
      params: { userId, groupId, skip, limit },
      cancelToken: cancelToken?.token,
    });
  console.log(res);
  return res.data.post;
};

export const retrievePostAPI = async (
  userId: string,
  postId: string
): Promise<PostInterface> => {
  const res = await axios.get(RETRIEVE_POST_URL, {
    params: { userId, postId },
  });
  console.log(res);
  return res.data;
};

export interface CommentCreationInterface {
  text?: string;
  attachments?: { fileId: string }[];
  postId: string;
}

export const addCommentAPI = async (
  userId: string,
  comment: CommentCreationInterface,
  file?: File
): Promise<CommentInterface> => {
  const body = _.pick(comment, ["text", "attachments"]);
  const formData = new FormData();

  formData.append("userId", userId);
  formData.append("postId", comment.postId);
  formData.append("comment", JSON.stringify(body));
  if (file) formData.append("file", file);
  return (
    await axios.post(ADD_COMMENT_URL, formData, {
      headers: {
        "content-type": "multipart/form-data",
      },
    })
  ).data;
};

export const savePostAPI = async (userId: string, postId: string) => {
  const res = await axios.post(SAVE_POST_URL, null, {
    params: { userId, postId },
  });
  return res.data;
};

export const unsavePostAPI = async (userId: string, postId: string) => {
  const res = await axios.delete(UNSAVE_POST_URL, {
    params: { userId, postId },
  });
  return res.data;
};

export const retrieveSavedPostsAPI = async (
  userId: string,
  skip?: number,
  limit?: number
) => {
  const res = await axios.get(RETRIEVE_SAVED_POSTS_URL, {
    params: { userId, skip, limit },
  });
  return res.data as PostInterface[];
};

export const MOCK_USER: PersonDto = {
  name: "Test",
  gender: "Male",
  personId: "1",
  surname: "Tester",
  leaf: false,
  horizontal: false,
};

export const MOCK_POST: PostInterface = {
  postId: "1",
  author: MOCK_USER,
  text: "Lorem Ipsum Dorem. Lorem Ipsum Dorem. Lorem Ipsum Dorem. Lorem Ipsum Dorem. Lorem Ipsum Dorem. Lorem Ipsum Dorem. Lorem Ipsum Dorem.",
  attachments: undefined,
  groupId: undefined,
  creationDate: "10/09/2021",
  timestamp: "1632484839",
  commentCounter: "3",
  isFavourite: false,
};

export const MOCK_COMMENT: CommentInterface = {
  attachments: [],
  author: MOCK_USER,
  commentId: "1",
  creationDate: "10/09/2021",
  timestamp: "1632484839",
  post: MOCK_POST,
  text: "Lorem Ipsum Dorem. Lorem Ipsum Dorem. Lorem Ipsum Dorem. Lorem Ipsum Dorem. Lorem Ipsum Dorem. Lorem Ipsum Dorem. Lorem Ipsum Dorem.",
};
