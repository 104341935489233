import { async } from "@firebase/util";
import axios from "axios";
import { PersonDto } from "../components/tree/TreeTypes";
import { API_URL } from "../Constants";
import {
  StripePrice,
  Subscription,
  SubscriptionType,
} from "../types/PaymentTypes";

const CHECKOUT_URL = API_URL + "/graph/payment/create-checkout-session";
const PORTAL_URL = API_URL + "/graph/payment/create-portal-session";
const FORCE_DELETE_URL = API_URL + "/graph/payment/forceCancelSubscription";
const RETRIEVE_SUBS_URL = API_URL + "/graph/payment/retrieveSubscriptions";
const RETRIEVE_ACTIVE_SUB_URL =
  API_URL + "/graph/payment/retrieveActiveSubscription";
const RETRIEVE_SUBS_TYPES_URL = API_URL + "/graph/payment/types";
const CHANGE_PLAN_URL = API_URL + "/graph/payment/changePlan";
const SHARE_SUB_URL = API_URL + "/graph/payment/shareSubscription";
const ACCEPT_SHARED_SUB_URL =
  API_URL + "/graph/payment/acceptSharedSubscription";
const GET_SHARED_SUB_URL =
  API_URL + "/graph/payment/retrieveSubscriptionByShareId";
const REMOVE_MEMBER_URL = API_URL + "/graph/payment/removeGroupMember";

const GET_PRICES_URL = API_URL + "/graph/payment/prices";

export const getCheckoutSession = async (
  userId: string,
  price_id: string,
  customerId?: string
) => {
  const res = await axios.post(CHECKOUT_URL, null, {
    params: {
      userId,
      price_id,
      customerId,
    },
  });

  console.log(res);
  return res.data;
};

export const getPortalSession = async (subscriptionId: string) => {
  const res = await axios.get(PORTAL_URL, {
    params: {
      subscriptionId,
    },
  });

  console.log(res);
  return res.data;
};

export const changePlan = async (
  subscriptionId: string,
  newPriceId: string
) => {
  const res = await axios.patch(CHANGE_PLAN_URL, null, {
    params: {
      subscriptionId,
      newPriceId,
    },
  });
  return res.status === 200;
};

export const removeSubMember = async (
  subscriptionId: string,
  memberId: string
) => {
  const res = await axios.delete(REMOVE_MEMBER_URL, {
    params: {
      subscriptionId,
      memberId,
    },
  });
};

export const shareSubscription = async (
  subscriptionId: string,
  email: string
) => {
  const res = await axios.put(SHARE_SUB_URL, null, {
    params: {
      subscriptionId,
      email,
    },
  });
};

export const acceptSharedSubscription = async (
  shareId: string,
  username: string
) => {
  const res = await axios.put(ACCEPT_SHARED_SUB_URL, null, {
    params: {
      shareId,
      username,
    },
  });
  return res.status === 200;
};

export const getSharedSubscription = async (shareId: string) => {
  const res = await axios.get(GET_SHARED_SUB_URL, {
    params: {
      shareId,
    },
  });

  return res.data as Subscription | undefined;
};

export const forceDeleteSubscription = async (subscriptionId: string) => {
  const res = await axios.delete(FORCE_DELETE_URL, {
    params: {
      subscriptionId,
    },
  });

  console.log(res);
  return res.status === 200;
};

export const getSubscriptionTypes = async () => {
  const res = await axios.get(RETRIEVE_SUBS_TYPES_URL);

  console.log(res);
  return res.data as SubscriptionType[];
};

export const getActiveSubscription = async (userId: string) => {
  const res = await axios.get(RETRIEVE_ACTIVE_SUB_URL, {
    params: {
      userId,
    },
  });

  console.log(res);
  return res.data as Subscription;
};

export const getSubscriptions = async (userId: string) => {
  const res = await axios.get(RETRIEVE_SUBS_URL, {
    params: {
      userId,
    },
  });

  console.log(res);
  return res.data as Subscription[];
};

export const getPrices = async () => {
  const res = await axios.get(GET_PRICES_URL);

  return res.data as StripePrice[];
};
