import MainLayout from "../layouts/MainLayout";
import "./../style/style.scss";
import {
  DownCircleOutlined,
  DropboxOutlined,
  LeftOutlined,
  LineHeightOutlined,
  RightOutlined,
  UpCircleOutlined,
} from "@ant-design/icons";
import React, { useState, useEffect, useRef, ReactElement } from "react";
import { FileLayout } from "../components/files/FileLayout";
import {
  API_URL,
  ROUTE_FILES,
  ROUTE_FOLDER,
  ROUTE_OAUTH_RESPONSE,
} from "../Constants";
import { useHistory } from "react-router-dom";
import { FilesHeader } from "../components/files/FilesHeader";
import { AlbumInterface, getLabelOf } from "../types/AlbumTypes";
import {
  createAlbumAPI,
  findAnnualAlbumsAPI,
  findFilesPlacesAPI,
  retrieveSharedAlbumAPI,
  shareAlbumAPI,
  unshareAlbumAPI,
} from "../service/albumAPI";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import {
  resetAuth,
  retrieveStorage,
  selectAlbums,
  selectProfile,
  selectTagAlbums,
  selectTags,
  updateAlbums,
  updateTags,
} from "../store/auth/authSlice";
import { AddDrawer } from "../components/files/AddDrawer";
import { Button, message, Modal, Spin, Tooltip } from "antd";
import AlbumModal from "../components/files/AlbumModal";
import {
  deleteFiles,
  downloadFile,
  getDropboxFiles,
  getRecentFiles,
  unshareFileAPI,
} from "../service/fileAPI";
import { FileDto, FileFilterDto } from "../components/files/Types";
import { DUMMY_ALBUM_IDS } from "./Folder";
import FolderContainer from "../components/files/FolderContainer";
import TourManager, { TourHandler } from "../components/TourManager";
import { filesTour } from "../tour/FilesTour";
import { ShareAltOutlined, UsergroupAddOutlined } from "@ant-design/icons";
import ShareAlbumModal from "../components/files/ShareAlbumModal";
import { getLocalizedCountry } from "../util/GeoUtil";
import UploadManager from "../components/files/UploadManager";
import FileForm, { FileFormHandler } from "../components/files/FileForm";
import FileItem from "../components/files/FileItem";
import CreatePostFromFilesModal from "../components/CreatePostFromFilesModal";
import {
  MultiplePostCreationInterface,
  addMultiplePostAPI,
} from "../service/feedAPI";
import { SafeImage } from "../components/SafeImage";

import { toast } from "react-toastify";
export const dropboxRedirectUri = window.location.origin + ROUTE_OAUTH_RESPONSE;
enum Section {
  FOLDERS,
  RECENT_FILES,
}
const Files: React.FunctionComponent = () => {
  const [albums, setAlbums] = useState<AlbumInterface[]>([]);
  const [sharedAlbums, setSharedAlbums] = useState<AlbumInterface[]>([]);
  const [loading, setLoading] = useState(false);
  const [isCreatingAlbum, setIsCreatingAlbum] = useState(false);
  const [tempAlbum, settempAlbum] = useState<AlbumInterface>();
  const [annualAlbums, setAnnualAlbums] = useState<AlbumInterface[]>([]);
  const history = useHistory();
  const userId = useAppSelector(selectProfile)?.personId!;
  const dispatch = useAppDispatch();
  const params = new URLSearchParams(window.location.search);
  const safe: boolean = params.get("isProtected") === "true";

  const annual: boolean = params.get("annual") === "true";

  const [sharedAlbum, setSharedAlbum] = useState<AlbumInterface>();
  const [multiSelection, setMultiSelection] = useState<{
    [key: string]: FileDto | undefined;
  }>({});
  const [openShareModal, setOpenShareModal] = useState<boolean>(false);
  const places: boolean = params.get("places") === "true";
  const shared: boolean = params.get("shared") === "true";
  const temp: boolean = params.get("temp") === "true";

  const dropbox: boolean = params.get("dropbox") === "true";

  const paramCountry: string | null = params.get("country");
  const [placesAlbums, setPlacesAlbums] = useState<AlbumInterface[]>([]);
  const [cityAlbums, setCityAlbums] = useState<AlbumInterface[]>([]);
  const [selectedCountry, setSelectedCountry] = useState("");

  const [openSection, setopenSection] = useState<Section>();

  const [showFileViewerModal, setShowFileViewerModal] = useState(false);
  const [index, setindex] = useState(0);

  const tags = useAppSelector(selectTags);
  const tagAlbums = useAppSelector(selectTagAlbums);
  const userAlbums = useAppSelector(selectAlbums);
  const [currentView, setCurrentView] = useState<string>("shrinked");
  const [recentdata, setRecentData] = useState<FileDto[]>(); // show recent files state
  const [selectedFile, setSelectedFile] = useState<FileDto>();
  const [hide, setHide] = useState(true); //Hide the folders and recent files State
  const [changebutton, setChangeButton] = useState(true); // upword button state

  const [sharedFiles, setSharedFiles] = useState<FileDto[]>();
  const [fileToDelete, setFileToDelete] = useState<FileDto>();
  const [copiedFile, setCopiedFile] = useState<FileDto>();

  console.log("current recent data", recentdata);

  const currentFile = copiedFile ?? selectedFile;
  useEffect(() => {
    if (currentFile) {
      setmodalVisible(true);
    } else {
      setmodalVisible(false);
    }
  }, [currentFile]);

  useEffect(() => {
    if (dropbox) loadDropboxFiles();
    else loadFiles();
  }, [dropbox]);

  const loadDropboxFiles = () => {
    getDropboxFiles(userId).then(async (result) => {
      console.log("get dropbox data", result);
      setRecentData(result);
    });
  };

  const loadFiles = () => {
    let skip = 0;
    let limit = 10;
    getRecentFiles(userId, safe, skip, limit).then(async (result) => {
      console.log("get recent data", result);
      setRecentData(result);
    });
  };
  const isMyFile = (file: FileDto) => {
    return (
      file.uploadedBy != null && file.uploadedBy.uploader.personId === userId
    );
  };

  // Control flow of Downword Button

  const adjust_container = (): any => {
    if (currentView === "shrinked") {
      setCurrentView("expanded");
      setChangeButton(false);
      setHide(false);
      setopenSection(Section.FOLDERS);
    }
  };

  // Control flow of Upword Button

  const changeButton = () => {
    if (currentView === "expanded") {
      setCurrentView("shrinked");
      setChangeButton(true);
      setHide(true);
      setopenSection(undefined);
    }
  };

  const loadAlbums = async () => {
    let tempAlbum = userAlbums?.find((a) => a.name === DUMMY_ALBUM_IDS.TEMP);
    let albums = [...(userAlbums ?? [])];
    if (tempAlbum) {
      settempAlbum(tempAlbum);
      albums?.splice(albums.indexOf(tempAlbum), 1);
    }
    setAlbums(albums ?? []);
  };

  useEffect(() => {
    loadAlbums();
  }, [userAlbums]);

  const reloadAlbums = () => {
    setLoading(true);
    dispatch(updateAlbums());
    dispatch(updateTags({ safe, userId }));

    if (annual)
      findAnnualAlbumsAPI(userId)
        .then((map) => {
          let albums: AlbumInterface[] = [];
          const monthsMap: Map<string, number[]> = new Map();
          Object.entries(map).forEach(([key, value]) => {
            monthsMap.set(key, value);
          });
          monthsMap.forEach((months: number[], key: string) => {
            months.forEach((month) => {
              const date = new Date();
              date.setMonth(month - 1);
              let monthName = date.toLocaleString("it-IT", { month: "long" });
              let monthDigit = date.toLocaleString("it-IT", {
                month: "2-digit",
              });
              albums.push({
                albumId: DUMMY_ALBUM_IDS.ANNUAL + "_" + monthDigit + "_" + key,
                name: monthName + " " + key,
              });
            });
          });

          console.log(albums);
          setAnnualAlbums(albums);
        })
        .catch((err) => console.log(err));

    if (shared)
      retrieveSharedAlbumAPI(userId)
        .then((sharedAlbums) => {
          setSharedAlbums(sharedAlbums);
        })
        .catch((err) => console.log(err));

    console.log(places);
    if (places)
      findFilesPlacesAPI(userId)
        .then((map) => {
          let localPlacesAlbums: AlbumInterface[] = [];
          const placesMap: Map<string, string[]> = new Map();
          Object.entries(map).forEach(([key, value]) => {
            placesMap.set(key, value);
          });
          placesMap.forEach((cities: string[], country: string) => {
            localPlacesAlbums.push({
              albumId: country,
              name: country,
              cities: cities,
            });
          });

          console.log(localPlacesAlbums);
          setPlacesAlbums(localPlacesAlbums);
        })
        .catch((err) => console.log(err));

    setLoading(false);
  };

  useEffect(() => {
    reloadAlbums();
  }, [history.location]);

  useEffect(() => {
    if (paramCountry != null) return;

    setCityAlbums([]);
    setSelectedCountry("");
  }, [places, paramCountry]);

  useEffect(() => {
    if (temp && tempAlbum) {
      openFolder(tempAlbum.albumId);
    }
  }, [temp]);

  useEffect(() => {
    if (paramCountry != null && placesAlbums.length > 0) {
      let temp = placesAlbums.find((a) => a.albumId == paramCountry);
      if (temp != null) openPlaceAlbum(temp);
    }
  }, [placesAlbums]);

  const openFolder = (id: string) => {
    history.push(
      ROUTE_FOLDER.replace(":id", id) +
        "?page=1&filesPerPage=5" +
        (safe ? "&isProtected=true" : "")
    );
  };

  const openMatchesFolder = (
    filter: FileFilterDto,
    fileTypes: string[] | undefined
  ) => {
    let path =
      `all?filter=${JSON.stringify(filter)}` +
      (safe ? "&isProtected=true" : "") +
      (fileTypes ? `&fileTypes=${fileTypes.join(",")}` : "") +
      "&page=1&filesPerPage=5";
    history.push(ROUTE_FOLDER.replace(":id", path));
  };

  const openPlaceAlbum = (album: AlbumInterface) => {
    let localCityAlbums: AlbumInterface[] = [];
    album.cities?.forEach((city) => {
      localCityAlbums.push({
        albumId: city + "_" + album.albumId,
        name: city,
      });
    });

    setSelectedCountry(album.albumId);
    setCityAlbums(localCityAlbums);
    if (paramCountry == null)
      history.push(ROUTE_FILES + "?places=true&country=" + album.albumId);
  };

  const createNewAlbum = (name: string) => {
    setLoading(true);
    setIsCreatingAlbum(false);
    createAlbumAPI(userId, name)
      .then(() => reloadAlbums())
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  const shareAlbum = async (shareIds: string[], unshareIds: string[]) => {
    if (!sharedAlbum?.albumId) return setOpenShareModal(false);
    try {
      if (shareIds.length > 0)
        await shareAlbumAPI(userId!, sharedAlbum?.albumId, shareIds);
      if (unshareIds.length > 0)
        await unshareAlbumAPI(userId!, sharedAlbum?.albumId, unshareIds);
      toast.success("Modifiche effettuate", {
        position: "bottom-right",
        hideProgressBar: true,
      });
      setOpenShareModal(false);
    } catch (error) {
      console.log(error);
    }
  };

  const addAlbums = () => {
    let toAdd = 0;

    while ((albums.length + toAdd + 2) % 3 !== 0) {
      toAdd += 1;
    }

    while (albums.length + toAdd + 2 < 9) {
      toAdd += 3;
    }

    let addDrawers = [];
    if (toAdd === 0) toAdd += 3;
    for (let i = 0; i < toAdd; i++) {
      addDrawers.push(<AddDrawer onClick={() => setIsCreatingAlbum(true)} />);
    }
    return addDrawers;
  };

  const container = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (container && container.current) {
      container.current.scrollTop = 0;
    }
  });

  const tourRef = useRef<TourHandler>(null);
  const fileFormRef = useRef<FileFormHandler>(null);

  const [modalVisible, setmodalVisible] = useState(false);

  const handleFileSelection = (file: FileDto, selected: boolean) => {
    setMultiSelection((data) => ({
      ...data,
      [file.fileId]: selected ? file : undefined,
    }));
  };

  const createMultiplePost = (
    dto: MultiplePostCreationInterface,
    groupIds: string[]
  ) => {
    addMultiplePostAPI(userId!, dto, groupIds)
      .then(() => message.success("Post aggiunti"))
      .catch((err) => {
        console.log(err);
        message.error("C'è stato un errore nella creazione dei post");
      })
      .finally(() => {
        console.log("Post creati");
        setSharedFiles(undefined);
        loadFiles();
      });
  };

  const unshareFile = async (groupId: string, fileId: string) => {
    unshareFileAPI(userId!, fileId, groupId)
      .then(() => {
        message.success("Condivisione annullata");
        loadFiles();
      })
      .catch((error) => {
        console.log(error);
        message.error(
          "C'è stato un errore nell'annullamento della condivisione"
        );
      });
  };

  const [selectImage, setselectImage] = useState(false);
  const LeftClick_Function = () => {
    if (index !== 0) {
      setindex(index - 1);
    }
  };
  const RightClick_Function = () => {
    if (index !== recentdata!.length - 1) {
      setindex(index + 1);
    }
  };

  const getFolders = () => {
    var folders: { name: string; element: ReactElement }[] = [];

    if (!annual && !places)
      [
        ...(shared ? albums.filter((a) => a.albumSharedToPerson) : albums),
        ...(shared ? [] : tagAlbums ?? []),
      ].forEach((a) => {
        folders.push({
          name: a.name,
          element: (
            <FolderContainer
              icon={
                a.albumSharedToPerson != null ? (
                  <ShareAltOutlined
                    onClick={(e) => {
                      e.stopPropagation();
                      setSharedAlbum(a);
                      setOpenShareModal(true);
                    }}
                  />
                ) : undefined
              }
              key={a.albumId}
              label={getLabelOf(a)}
              onClick={() => openFolder(a.albumId)}
              className="drawer cell"
              tooltip={a.albumSharedToPerson != null ? "Album condiviso" : ""}
            />
          ),
        });
      });

    if (!annual && !places && !safe)
      sharedAlbums.forEach((a) => {
        folders.push({
          name: a.name,
          element: (
            <FolderContainer
              icon={<UsergroupAddOutlined />}
              key={a.albumId}
              label={getLabelOf(a)}
              onClick={() =>
                openFolder(DUMMY_ALBUM_IDS.SHARED + "_" + a.albumId)
              }
              className="drawer cell"
              tooltip={
                a.albumSharedToPerson != null && a.creator != null
                  ? "Condiviso da " + a.creator
                  : ""
              }
            />
          ),
        });
      });

    if (annual && !places && !shared)
      annualAlbums.forEach((a) => {
        folders.push({
          name: a.name,
          element: (
            <FolderContainer
              key={a.albumId}
              label={getLabelOf(a)}
              onClick={() => openFolder(a.albumId)}
              className=""
            />
          ),
        });
      });

    if (!annual && !shared && places && selectedCountry)
      folders.push({
        name: "tutti",
        element: (
          <FolderContainer
            key={selectedCountry}
            label={"TUTTI"}
            onClick={() =>
              openFolder(DUMMY_ALBUM_IDS.PLACE + "_" + selectedCountry)
            }
            className="drawer cell"
          />
        ),
      });

    if (!annual && !shared && places && !paramCountry)
      placesAlbums.forEach((a) => {
        folders.push({
          name: a.name,
          element: (
            <FolderContainer
              key={a.albumId}
              label={getLocalizedCountry(getLabelOf(a))}
              onClick={() => openPlaceAlbum(a)}
              className="drawer cell"
            />
          ),
        });
      });

    if (!annual && !shared && places)
      cityAlbums.forEach((a) => {
        folders.push({
          name: a.name,
          element: (
            <FolderContainer
              key={a.albumId}
              label={getLabelOf(a)}
              onClick={() =>
                openFolder(DUMMY_ALBUM_IDS.PLACE + "_" + a.albumId)
              }
              className="drawer cell"
            />
          ),
        });
      });

    return folders
      .sort((a, b) =>
        a.name
          .replace("#", "")
          .toLowerCase()
          .localeCompare(b.name.replace("#", "").toLowerCase())
      )
      .map((f) => f.element);
  };

  return (
    <MainLayout
      hideFooter
      showHelp
      contentClassName="files-content"
      onHelp={() => {
        tourRef.current?.start();
      }}
    >
      <FileForm
        ref={fileFormRef}
        inSafe={safe}
        modalVisible={modalVisible}
        onClose={(update, copiedFile) => {
          setCopiedFile(copiedFile);
          setSelectedFile(undefined);
          setmodalVisible(false);
          if (update) loadFiles();
        }}
        readOnly={selectedFile && !isMyFile(selectedFile)}
        currentAlbum={"all"}
        currentFile={copiedFile ? copiedFile : selectedFile}
        onLinkedAlbum={() => {
          history.push(
            selectedFile?.album?.albumId! + "?page=1&filesPerPage=5"
          );
        }}
      />
      <TourManager
        ref={tourRef}
        tour={filesTour}
        autorun
        disableScrolling
        spotlightRadius={5}
        spotlightPadding={5}
        onComplete={() => {}}
      />

      {showFileViewerModal && (
        <Modal
          visible={showFileViewerModal}
          cancelText="Chiudi"
          onCancel={() => {
            setShowFileViewerModal(false);
          }}
          okButtonProps={{ hidden: true }}
          onOk={undefined}
        >
          <div className="folderImage">
            <LeftOutlined onClick={LeftClick_Function} />
            <SafeImage
              onClick={() => {
                setselectImage(true);
              }}
              style={{
                borderRadius: "5%",
                height: 250,
                width: 250,
                objectFit: "cover",
              }}
              preview
              file={recentdata![index]}
              src={
                recentdata![index].fileType == "image"
                  ? recentdata![index].uri
                  : recentdata![index].thumbnail_uri
              }
              fallback={
                <div
                  style={{ width: "100%", height: 300 }}
                  className="centered"
                >
                  Errore
                </div>
              }
            />
            <RightOutlined onClick={RightClick_Function} />
          </div>
        </Modal>
      )}

      <UploadManager
        type="drag"
        disabled={modalVisible}
        showBorder={false}
        onFiles={(f) => {
          setmodalVisible(true);
          fileFormRef.current?.onFiles(f);
        }}
        onSingleFile={(f) => {
          setmodalVisible(true);
          fileFormRef.current?.onSingleFile(f);
        }}
        onFolder={(f) => {
          setmodalVisible(true);
          fileFormRef.current?.onFolder(f);
        }}
        onPreviewReady={(f) => {
          fileFormRef.current?.onPreviewReady(f);
        }}
      >
        <div className="files" style={{ overflow: "auto" }}>
          <FilesHeader
            onOpenFileForm={() => setmodalVisible(true)}
            onCreateAlbum={() => {
              setIsCreatingAlbum(true);
            }}
            onFilter={(filter, fileTypes) => {
              openMatchesFolder(filter, fileTypes);
            }}
            onClose={() => null}
            onLinkedAlbum={() => null}
          />
          <div
            style={{
              display: "flex",
              flex: "auto",
              height: openSection === Section.FOLDERS ? "inherit" : "unset",
              minHeight: 300,

              alignItems: "center",
              overflow: "hidden",
            }}
          >
            <FileLayout
              expanded={openSection === Section.FOLDERS}
              title={safe ? "LA MIA CASSAFORTE" : "IL MIO ARCHIVIO"}
              height={`calc(100% - 113px)`}
              tempAlbumId={tempAlbum?.albumId}
            >
              {/* Grid layout of showing all folders */}
              <div className="scroll_class">
                <div
                  className="drawer-container"
                  ref={container}
                  style={
                    safe
                      ? {
                          gridAutoRows: "max-content",
                          borderWidth: 10,
                        }
                      : undefined
                  }
                >
                  {/**
                   * 
                   *     {!annual && !shared && !places && (
                    <FolderContainer
                      label={"TUTTI"}
                      onClick={() => openFolder(DUMMY_ALBUM_IDS.ALL)}
                      className="drawer cell all"
                    />
                  )}

                  

                  {!annual && !places && !shared && tempAlbum && (
                    <FolderContainer
                      label={"SCRIVANIA"}
                      onClick={() => openFolder(tempAlbum.albumId)}
                      className="drawer cell desk"
                    />
                  )}
 */}
                  {!annual && !places && !safe && shared && (
                    <>
                      <FolderContainer
                        label={"TUTTI I FILES CONDIVISI"}
                        onClick={() => openFolder(DUMMY_ALBUM_IDS.SHARED_ALL)}
                        className="drawer cell shared"
                      />
                      <FolderContainer
                        label={"CONDIVISI CON ME"}
                        onClick={() => openFolder(DUMMY_ALBUM_IDS.SHARED)}
                        className="drawer cell shared"
                      />
                    </>
                  )}

                  {loading ? <Spin size="large" /> : getFolders()}
                </div>
              </div>
            </FileLayout>

            {/* Downword and Upword Buttons */}
            {changebutton ? (
              <DownCircleOutlined
                onClick={adjust_container}
                className="DownOutlined"
              />
            ) : (
              <UpCircleOutlined
                onClick={changeButton}
                className="Upward_Button"
              />
            )}
          </div>

          <div style={{ margin: 10 }}>
            <b>Ultimi Files...</b>
          </div>

          {/* Recent Files Code */}

          {hide ? (
            <div
              className="hidden"
              style={{ overflow: "auto", minHeight: 200, maxHeight: 250 }}
            >
              {recentdata?.map((file, idx) => (
                <div
                  key={idx}
                  style={
                    idx % 2 === 0 ? { backgroundColor: "#f8f8f8" } : undefined
                  }
                >
                  <FileItem
                    gridMode={false}
                    key={file.fileId}
                    file={file}
                    openFileImage={() => {
                      setShowFileViewerModal(true);
                      setindex(idx);
                    }}
                    readOnly={!isMyFile(file)}
                    selectFile={() => setSelectedFile(file)}
                    downloadFile={() => downloadFile(file)}
                    openDeleteModal={() => setFileToDelete(file)}
                    openShareModal={() => setSharedFiles([file])}
                    hidecheckbtn
                  />
                </div>
              ))}
            </div>
          ) : null}

          {/** 
          <FileLayout title="LA TUA CASSAFORTE" height="50%">
                  <div className="border">
                    <Safe />
                  </div>
                </FileLayout>
        */}

          <Modal
            visible={!!fileToDelete}
            footer={[
              <Button
                type="primary"
                onClick={() => {
                  if (fileToDelete)
                    deleteFiles([fileToDelete?.fileId])
                      .then((res) => {
                        setFileToDelete(undefined);
                        loadFiles();
                      })
                      .finally(() => {
                        dispatch(retrieveStorage());
                      });
                }}
              >
                Elimina
              </Button>,
              <Button
                onClick={() => {
                  setFileToDelete(undefined);
                }}
              >
                Annulla
              </Button>,
            ]}
            title="Elimina documento"
            centered
            destroyOnClose
            maskStyle={{
              backdropFilter: "blur(3px)",
              backgroundColor: "unset",
            }}
            bodyStyle={{ padding: 40 }}
            onCancel={() => {
              setFileToDelete(undefined);
            }}
            width={"fit-content"}
          >
            <div>{"Sicuro di voler eliminare il documento selezionato?"}</div>
          </Modal>

          <AlbumModal
            open={isCreatingAlbum}
            onClose={() => setIsCreatingAlbum(false)}
            onSubmit={createNewAlbum}
          />

          <ShareAlbumModal
            visible={openShareModal}
            userId={userId!}
            album={sharedAlbum}
            onSubmit={shareAlbum}
            onClose={() => setOpenShareModal(false)}
          />

          <CreatePostFromFilesModal
            files={sharedFiles}
            userId={userId!}
            close={() => setSharedFiles(undefined)}
            createMultiplePost={createMultiplePost}
            onUnshareFile={unshareFile}
          />
        </div>
      </UploadManager>
    </MainLayout>
  );
};

export default Files;
