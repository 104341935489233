import { CloudUploadOutlined } from "@ant-design/icons";
import { message, Upload, UploadProps } from "antd";
import { RcFile } from "antd/lib/upload";
import Dragger, { DraggerProps } from "antd/lib/upload/Dragger";
import { UploadFile } from "antd/lib/upload/interface";
import update from "immutability-helper";
import { type } from "os";
import { FunctionComponent, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { JsxElement } from "typescript";
import { MAX_UPLOAD_SIZE } from "../../Constants";
import { getBase64 } from "../../util/FileUtil";

interface SuperFile {
  file: RcFile;
  src: string;
}

interface UploadManagerPropsGeneric {
  fileType?: string;
  className?: string;
  showBorder?: boolean;
  disabled?: boolean;
  keepSelection?: boolean;
  onSingleFile: (file: RcFile) => void;
  onFiles: (files: SuperFile[]) => void;
  onPreviewReady: (uploadPreview: string | null) => void;
  onFolder: (name: string) => void;
}

interface UploadManagerPropsClick extends UploadManagerPropsGeneric {
  type: "click";
  fileType: "file" | "folder";
}

interface UploadManagerPropsDrag extends UploadManagerPropsGeneric {
  type: "drag";
  fileType?: "any";
}

type UploadManagerProps = UploadManagerPropsClick | UploadManagerPropsDrag;

export const isInputDirSupported = () => {
  var tmpInput = document.createElement("input");
  return (
    "webkitdirectory" in tmpInput ||
    "mozdirectory" in tmpInput ||
    "odirectory" in tmpInput ||
    "msdirectory" in tmpInput ||
    "directory" in tmpInput
  );
};

const UploadManager: FunctionComponent<UploadManagerProps> = ({
  children,
  disabled,
  className,
  type,
  fileType,
  showBorder = type === "click" ? false : true,
  keepSelection = false,
  onFolder,
  onFiles,
  onPreviewReady,
  onSingleFile,
}) => {
  const photo_height = "25vh";

  const onMultipleFiles = (list: RcFile[]) => {
    console.log("multiple files");
    console.log(list);
    let tempList: SuperFile[] = [];
    list.forEach((file, i) => {
      if ((file.size ?? 0) >= MAX_UPLOAD_SIZE) {
        toast.warning(`Il file ${file.name} supera il limite di 100 MB`,{
          position: "bottom-right",
          hideProgressBar: true,
        });
      } else {
        console.log(file);

        const path: string = (file as any).webkitRelativePath;

        if (path && path.includes("/")) {
          onFolder(path.split("/")[0]);
        }

        if (file.type.includes("image")) {
          getBase64(file, (imageUrl) => {
            tempList = update(tempList, {
              $push: [{ file, src: imageUrl ?? "" }],
            });
            console.log(tempList);

            if (i === list.length - 1) {
              console.log("set");
              onFiles(tempList);
            }
          });
        } else {
          tempList = update(tempList, {
            $push: [{ file, src: "" }],
          });
          console.log(tempList);

          if (i === list.length - 1) {
            console.log("set");
            onFiles(tempList);
          }
        }
      }
    });
    console.log(tempList);
  };

  const [filesList, setfilesList] = useState<UploadFile[]>([]);

  const uploadProps: UploadProps = {
    //maxCount={1}
    fileList: filesList,
    className,
    disabled: disabled,
    directory:
      isInputDirSupported() && (type === "drag" || fileType === "folder"),
    openFileDialogOnClick: type === "click",
    multiple: true,
    style: { height: photo_height },
    showUploadList: false,
    beforeUpload: (file, list) => {
      list = list.filter((f) => f.type !== "");
      if (list.length > 1) {
        onMultipleFiles(list.filter((f) => !f.name.startsWith(".")));
      } else {
        onSingleFile(file);
      }
      return false;
    },
    customRequest: async (options) => {
      console.log(options);
      //await uploadFile(options.file, userId!);
    },
    onChange: (info) => {
      console.log(info);

      info.fileList = info.fileList.filter((f) => f.type !== "");
      let newList = [...filesList, ...info.fileList];
      setfilesList((l) => [...l, ...info.fileList]);
      if (newList && newList.length > 1) {
        onMultipleFiles(
          newList
            .filter((f) => !f.name.startsWith("."))
            .map((f) => f.originFileObj!)
        );

        return;
      }
      if (
        newList &&
        newList[newList.length - 1] &&
        newList[newList.length - 1].originFileObj
      ) {
        console.log(newList);
        if ((newList[newList.length - 1].size ?? 0) >= MAX_UPLOAD_SIZE) {
          return;
        }
        // Get this url from response in real world.
        getBase64(newList[newList.length - 1].originFileObj!, (imageUrl) => {
          onPreviewReady(imageUrl);
        });
      }
    },
  };
  const [counter, setcounter] = useState(0);

  const dragging = !disabled && counter !== 0;
  return (
    <>
      {type === "click" ? (
        <Upload {...uploadProps}> {children}</Upload>
      ) : (
        <Dragger
          {...uploadProps}
          style={{
            background: "unset",
            border: showBorder ? "1px dashed #d9d9d9" : "unset",
            cursor: "default",
          }}
        >
          {dragging && (
            <div
              className="drop-overlay"
              onDragEnter={() => {
                setcounter((c) => c + 1);
              }}
              onDragLeave={() => {
                setcounter((c) => c - 1);
              }}
              onDrop={() => {
                console.log("drop");
                setfilesList([]);
                setcounter(0);
              }}
            >
              <CloudUploadOutlined style={{ fontSize: 40 }} />
              {"Rilascia per caricare i tuoi files"}
            </div>
          )}

          <div
            className={"dragger-content" + (dragging ? " blur" : "")}
            onDragEnter={() => {
              setcounter((c) => c + 1);
            }}
            onDragLeave={() => {
              setcounter((c) => c - 1);
            }}
            onDrop={() => {
              console.log("drop");
              setfilesList([]);
              setcounter(0);
            }}
          >
            {children}
          </div>
        </Dragger>
      )}
    </>
  );
};

export default UploadManager;
