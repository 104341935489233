import { Menu } from "antd";
import SubMenu from "antd/lib/menu/SubMenu";
import React from "react";
import { isMobile } from "react-device-detect";
import { useHistory } from "react-router";
import {
  ROUTE_FEED,
  ROUTE_FILES,
  ROUTE_HELP,
  ROUTE_PAYMENTS,
  ROUTE_PERSON_VIEW,
  ROUTE_PROFILE,
  ROUTE_TREE,
} from "../Constants";
import i18n from "i18next";
import { authLogout, selectProfile } from "../store/auth/authSlice";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { resetTree } from "../store/tree/treeSlice";

export const UserMenuItems = () => {
  return [
    <Menu.Item key="profile">Profilo</Menu.Item>,

    <Menu.Item key="payments">Abbonamento</Menu.Item>,

    <Menu.Divider key="1" />,

    <Menu.Item key="help">Supporto</Menu.Item>,

    <Menu.Item key="logout">{i18n.t("Logout")}</Menu.Item>,
  ];
};

export const UserMenu: React.FunctionComponent = (props) => {
  const history = useHistory();

  const dispatch = useAppDispatch();
  const logout = () => {
    dispatch(authLogout());
  };

  const profile = useAppSelector(selectProfile);
  const userName = profile?.name + " " + profile?.surname;

  if (!isMobile)
    return (
      <Menu
        onClick={(info) => {
          switch (info.key) {
            case "logout": {
              logout();
              break;
            }
            case "tree": {
              history.push(ROUTE_PERSON_VIEW.replace(":id", ""));
              break;
            }
            case "files": {
              history.push(ROUTE_FILES);
              break;
            }
            case "feed": {
              history.push(ROUTE_FEED);
              break;
            }
            case "profile": {
              history.push(ROUTE_PROFILE);
              break;
            }
            case "help": {
              history.push(ROUTE_HELP);
              break;
            }
            case "payments": {
              history.push(ROUTE_PAYMENTS);
              break;
            }
          }
        }}
      >
        {UserMenuItems()}
      </Menu>
    );
  return (
    <SubMenu key="sub1" title={userName} {...props}>
      {UserMenuItems()}
    </SubMenu>
  );
};
